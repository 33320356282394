import { useEffect } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../../components/SearchInput/SearchInput';
import { TableStyles } from '../../utils/Constants';
import { useNavigate, useParams } from 'react-router-dom';
import { store } from '../../redux/store';
import { ArrowIcon } from '../../utils/Icons';
import { CustomMaterialPagination } from '../../components/CustomPagination/CustomPagination';
import { useAppSelector } from '../../hooks/hooks';
import { exportCustomers, fetchCustomerOrders, fetchCustomers } from './hooks/customerListApi';
import { changeFillter } from './hooks/CustomersSlice';
import { columns } from './hooks/ScreenHelper';
import { delayInput } from '../../utils/Helpers';
export const CustomerOrdersPage: React.FC = () => {

    const navigate = useNavigate();
    const [t] = useTranslation();
    const { customerId } = useParams();
    const {customerOrders, customerOrdersFilters} = useAppSelector(state => state.customers);

    useEffect(() => {
        getOrders();
    }, [customerOrdersFilters]);

    const getOrders = async () => {
        store.dispatch(fetchCustomerOrders(customerId))
    }

    const exportXLSX = async () => {
        store.dispatch(exportCustomers())
    }

    const handleSort = async (column, sortDirection) => {
        console.log(column)
        store.dispatch(changeFillter([
            {filterName: "sortByColumn", value: column.name},
            {filterName: "sortBy", value: sortDirection}
        ]));
    };

    return <>
        <Card className='p-4 p-md-5'>
            <Col>
                <div className='d-flex flex-column flex-lg-row justify-content-between'>
                <div className='d-flex flex-column flex-md-column justify-content-between'>
                        <h4>{t('customers.customer_orders')} (77)</h4>
                        <p>{t('common.name')} : </p>
                    </div>
                    
                    <Button style={{ width: 'max-content' }} variant='dark' onClick={() => exportXLSX()}>{t('common.export-xlsx')}</Button>
                </div>
                <Row>
                    <Col>
                       
                    <DataTable
                            columns={columns(t, navigate)}
                            data={customerOrders?.MerchantOrderListItemRs}
                            customStyles={TableStyles}
                            pagination
                            paginationComponent={CustomMaterialPagination}
                            paginationTotalRows={customerOrders?.totalOrders}
                            paginationServer
                            sortIcon={<ArrowIcon className='mx-1' style={{ width: '10px' }} />}
                            sortServer
                            onSort={handleSort}
                            onChangePage={(page: number, totalRows: number) => {
                                store.dispatch(changeFillter([{filterName: "page", value: page}]))
                            }}
                            onChangeRowsPerPage={(currentRowsPerPage: number, currentPage: number) => {
                                store.dispatch(changeFillter([
                                    {filterName: "page", value: currentPage},
                                    {filterName: "size", value: currentRowsPerPage}
                                ]));
                            }}
                        />
                    </Col>
                </Row>
            </Col>
        </Card>
    </>;
}
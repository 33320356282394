import { PaginationComponentProps } from "react-data-table-component";
import { PaginationChangePage, PaginationChangeRowsPerPage } from "react-data-table-component/dist/src/DataTable/types";
import Pagination from 'rc-pagination';
import './style.scss';
import { useTranslation } from "react-i18next";
import { useEffect } from "react";


export const CustomMaterialPagination: React.FC<PaginationComponentProps> = (props: {
    rowsPerPage,
    rowCount,
    currentPage,
    onChangePage: PaginationChangePage,
    onChangeRowsPerPage: PaginationChangeRowsPerPage,
}
) => {
    const [t, i18next] = useTranslation();
    const rowsPerPageOptions = [ 30, 50, 100];
    useEffect(() => {
        props.onChangeRowsPerPage(30, 1);
        }, [])
    return (
        <>
            <div className=" border-top my-4"></div>
            <div className="d-flex flex-row justify-content-center align-content-center">
                <div className="dropdown">
                    <a className="btn btn-outline-gray border dropdown-toggle shadow-none" id="dropdownTable" data-bs-toggle="dropdown" aria-expanded="false">
                        {`${props.rowsPerPage} ${t('common.items')}`}
                    </a>
                    <ul className="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5" aria-labelledby="dropdownTable">
                        {rowsPerPageOptions.map((option, i) =>
                            <li><a className="dropdown-item border-radius-md" key={i} onClick={() => props.onChangeRowsPerPage(option, props.currentPage)}>{`${option} ${t('common.items')}`}</a></li>
                        )
                        }
                    </ul>
                </div>
                <div className="mx-auto my-auto">

                    <Pagination
                        onChange={props.onChangePage}
                        current={props.currentPage}
                        total={props.rowCount}
                        prevIcon={<i className={`fa ${i18next.language === 'ar' ? 'fa-angle-right' : 'fa-angle-left'}`} />}
                        nextIcon={<i className={`fa ${i18next.language === 'ar' ? 'fa-angle-left' : 'fa-angle-right'}`} />}
                        showTotal={(total, range) => `${range[0]} - ${range[1]} ${t('common.of')} ${total}`}
                        pageSize={props.rowsPerPage}
                        locale={{
                            next_page:'',
                            prev_page:'',
                        }}
                    />
                </div>
            </div>
        </>
    );
};

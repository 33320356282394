import API from '../../../hooks/api';

export interface IOrderCountData {
    x: string;
    y: number;
}

export const orderCountAPI = async (startDate: string, endDate: string,
        callBack: (success: boolean, data: any,  errorMsg: string | null) => void = () => {}
    ) => {
        const url = `order/merchant/dashboard/order/count`;
    await API.get(url, {params: {startDate, endDate}})
    .then((res) => {
        const dataArray: IOrderCountData[] = [];
        Object.entries(res.data).forEach(([key, value]) => {
            dataArray.push({
                x: key,
                y: Number(value)
            });
        });

        // sort the dates (temp solution)
        dataArray.sort((a, b) => {
            return a.x.localeCompare(b.x);
        });



        // insert 0 for missing dates (temp solution)
        const firstDate = new Date(dataArray[0].x);
        const lastDate = new Date(dataArray[dataArray.length - 1].x);
        const diffTime = Math.abs(lastDate.getTime() - firstDate.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        const missingDates: IOrderCountData[] = [];
        for (let i = 1; i < diffDays; i++) {
            const currentDate = new Date(firstDate);
            currentDate.setDate(currentDate.getDate() + i);
            const dateString = currentDate.toISOString().split('T')[0];
            if (!dataArray.find((item) => item.x === dateString)) {
                missingDates.push({
                    x: dateString,
                    y: 0
                });
            }
        }
        dataArray.push(...missingDates);
        dataArray.sort((a, b) => {
            return a.x.localeCompare(b.x);
        });

        
        callBack(true, dataArray, null);
    }).catch((err) => {
        callBack(false, null, err);
    });
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../hooks/hooks';
import { listItems } from '../../hooks/MenuList';
import { LogoSmallArIcon, LogoSmallIcon, ProfileIcon } from '../../utils/Icons';

export const Navbar: React.FC = () => {
    const [t, i18n] = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();  
    const {name, company} = useAppSelector(state => state.user_profile);

    const toggleLanguage = (locale:string) => {
        i18n.changeLanguage(locale);
    }

    const onClickLogout = () => {
        localStorage.clear();
        window.location.reload();
    }
    
    return (
        <>
            {/* <!-- Navbar --> */}
        

            <nav className="navbar navbar-main navbar-expand-lg px-4 bg-white shadow-none border-radius-xl" id="navbarBlur" navbar-scroll="false">
                <div className="container-fluid py-1 px-3">
                    {/* <a className="navbar-brand m-0" href="/">
                        <LogoSmallIcon width={"50%"} />
                        <span className="ms-1 font-weight-bold"></span>
                    </a> */}
                    <a className="navbar-brand m-0 bg-white " href="/">
                        {
                            i18n.language === 'ar' ? <LogoSmallArIcon width={"100%"} /> : <LogoSmallIcon width={"50%"} />
                        }
                  <span className="ms-1 font-weight-bold"></span>
                </a>

                    <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
                    <div className="ms-md-auto pe-md-3 d-flex align-items-center"></div>
                    <ul className="navbar-nav  justify-content-end">
                        
                        <li className="nav-item d-flex align-items-center pe-3">
                            <a onClick={()=>{toggleLanguage('en')}}  className={"nav-link px-0 " + (i18n.language === 'en' ? "active" : null)} style={i18n.language === 'en' ? {fontWeight:'bold'} : {}}>
                                <span className="d-sm-inline d-none">English</span>
                            </a>
                        </li>

                        <li className="nav-item d-flex align-items-center pe-3">
                            <a onClick={()=>{toggleLanguage('ar')}} className={"nav-link px-0 " + (i18n.language === 'ar' ? "active" : null)} style={i18n.language === 'ar' ? {fontWeight:'bold'} : {}}>
                                <span className="d-sm-inline d-none">عربي</span>
                            </a>
                        </li>

                        <li className="nav-item d-flex align-items-center pe-2">
                            <span className='py-2 h-75' style={{ width: 1, background: "#EDEEEF" }}></span>
                        </li>
                        
                        <li className="nav-item dropdown pe-2 d-flex align-items-center">
                        <a  className="nav-link text-body p-0 dropdown-toggle" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" style={{ display: "contents" }}>
                        <div className="d-flex py-1 pe-2">
                            <div className="my-auto">
                                <ProfileIcon className="avatar avatar-sm me-3 " />
                            </div>
                            <div className="d-flex flex-column justify-content-center me-3">
                                <h6 className="text-sm font-weight-normal mb-1">
                                    <span className="font-weight-bold">{name}</span>
                                </h6>
                                <p className="text-xs text-secondary mb-0 ">
                                    {company}
                                </p>
                            </div>
                            </div>
                        </a>
                        <ul className="dropdown-menu px-2 py-3 me-sm-n4" aria-labelledby="dropdownMenuButton">
                            <li>
                                <a className="dropdown-item border-radius-md" onClick={onClickLogout}>
                                    <div className="d-flex py-1">
                                        <div className="d-flex flex-column justify-content-center">
                                            <h6 className="text-sm font-weight-normal mb-1">
                                                {t('buttons.logout')}
                                            </h6>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        </li>
                        <li className='nav-item  d-flex align-items-center d-lg-none'>
                            <span className='py-2 h-75 mx-2' style={{ width: 1, background: "#EDEEEF" }}></span>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </li>
                    </ul>
                    </div>
                </div>
                {/* Mobile Menu  */}
                <div className="container-fluid d-lg-none">
                    <div className="collapse " id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            {
                            listItems.map((item, index) => 
                                <li className="nav-item" key={index}>
                                    <a className={ (location.pathname === item.url ? 'active' : '') + " nav-link"} 
                                        onClick={() => navigate(item.url)}>
                                        <span className="nav-link-text ms-1">{t(item.name)}</span>
                                    </a>
                                </li>    
                            )}
                        </ul>
                    </div>
                </div>
                {/* End Mobile Menu */}
            </nav>
            {/* <!-- End Navbar --> */}
        </>
    );
}


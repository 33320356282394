import { useEffect, useReducer, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../../components/SearchInput/SearchInput';
import { StatusLabel } from '../../components/StatusLabel/StatusLabel';
import { TableStyles } from '../../utils/Constants';
import { useNavigate } from 'react-router-dom';
import { store } from '../../redux/store';
import { setSidebar } from '../../redux/reducers/settingsSlice';
import { ArrowIcon, FilterIcon } from '../../utils/Icons';
import { exportOrderListAPI, IOrderPagination, orderListAPI } from './hooks/orderListApi';
import { CustomMaterialPagination } from '../../components/CustomPagination/CustomPagination';
import { OrdersFiltersPanel } from './OrdersFiltersPanel';
import { IOrdersAggregates, ordersAggregatesAPI } from './hooks/ordersAggregatesApi';
import { orderExcelAPI } from './hooks/exportExcelApi';
export const OrdersPage: React.FC = () => {

    const navigate = useNavigate();
    const [t] = useTranslation();
    const [orders, setOrders] = useState<IOrderPagination>({
        currentPage: 1,
        pageCount: 0,
        pageSize: 0,
        totalOrders: 0,
        MerchantOrderListItemRs: [],
    });

    const [ordersAggregates, setOrdersAggregates] = useState<IOrdersAggregates>({
        minOrderAmount: 0,
        maxOrderAmount: 0,
        orderStatusCount: {
            CANCELLED: 0,
            UNDER_CANCEL: 0,
            PENDING: 0,
            DECLINED: 0
        }
    });

    const [showFilters, setShowFilters] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(30);
    const [searchString, setSearchString] = useState('');
    const [minAmount, setMinAmount] = useState();
    const [maxAmount, setMaxAmount] = useState();
    const [sortByColumn, setSortByColumn] = useState();
    const [sortBy, setSortBy] = useState('');
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [statuses, setStatuses] = useState();


    useEffect(() => {
        
        getOrders();

    }, [pageSize, pageNumber, sortBy, sortByColumn, searchString, minAmount, maxAmount, startDate, endDate, statuses]);

    useEffect(() => {
        setPageNumber(1);
    }, [searchString, minAmount, maxAmount, startDate, endDate, statuses]);

    useEffect(() => {
        ordersAggregatesAPI(
            (success, data, error) => {
                if (success) {
                    setOrdersAggregates(data);
                }
            });
    }, []);

    const getOrders = async () => {
        orderListAPI(
            pageNumber, pageSize, searchString,
            minAmount, maxAmount, sortBy, sortByColumn,
            startDate, endDate, statuses,
            (success, data, error) => {
                if (success) {
                    setOrders(data);
                }
            });
    }

    const exportOrders = async () => {
        exportOrderListAPI(
            pageNumber, pageSize, searchString,
            minAmount, maxAmount, sortBy, sortByColumn,
            startDate, endDate, statuses,
            (success, data, error) => {
                if (success) {
                    setOrders(data);
                }
            });
    }

    function filtersReducer(state, action) {
        switch (action.type) {
            case 'date':
                if(action.payload.length)
                {
                    setStartDate(action.payload[0].toDate().toISOString());
                    if(action.payload[1])
                    setEndDate(action.payload[1].toDate().toISOString());
                } else {
                    setStartDate(undefined);
                    setEndDate(undefined);
                }
                return { ...state, dates: action.payload };

            // to avoid showing the initial values as selected
            case 'amount':
                if (action.payload[0] === ordersAggregates.minOrderAmount && action.payload[1] === ordersAggregates.maxOrderAmount) {
                    setMinAmount(undefined);
                    setMaxAmount(undefined);
                    return { ...state, amount: [] };
                }
                else {
                    setMinAmount(action.payload[0]);
                    setMaxAmount(action.payload[1]);
                    return { ...state, amount: action.payload };
                }
            case 'status':
                setStatuses(action.payload);
                return { ...state, states: action.payload };
            default:
                return;
        }
    }

    const initialFiltersState = {
        dates: [],
        amount: [],
        states: [],
    };
    const [filtersState, dispatchFilter] = useReducer(filtersReducer, initialFiltersState);

    useEffect(() => {
        console.log(filtersState);
    }, [filtersState]);

    useEffect(() => { store.dispatch(setSidebar('show')) }, [])

    const columns = [
        {
            name: t('orders.order-date'),
            selector: row => row.creationDate,
            sortable: true,
            sortColumnName: 'creationDate',
        },
        {
            name: t('orders.order-reference-id'),
            selector: row => row.orderReferenceId,
            sortable: true,
            sortColumnName: 'orderReferenceId',
        },
        {
            name: t('orders.loan-id'),
            selector: row => row.loanId,
            sortable: true,
            sortColumnName: 'loanId',
        },
        {
            name: t('common.customer-name'),
            selector: row => row.customerEnglishFirstName ?? '',
            sortable: true,
            sortColumnName: 'customerEnglishFirstName',
        },
        {
            name: t('common.amount'),
            selector: row => row.amount,
            sortable: true,
            sortColumnName: 'amount',
        },
        {
            name: t('common.status'),
            selector: row => row.status,
            cell: row => <div className='d-flex flex-row justify-content-center my-1'>
                <StatusLabel status={row.status} subStatus={row?.orderSubStatus} />
            </div>,
            sortable: true,
            sortColumnName: 'status',
            style: {
                justifyContent: 'center',
            }

        },

    ];

    const handleSort = async (column, sortDirection) => {
        setSortByColumn(column.sortColumnName);
        setSortBy(sortDirection === 'asc' ? 'ASC' : 'Desc');
    };

    function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      }
    const downloadOrders = () => {
        orderExcelAPI(
            minAmount, maxAmount, sortBy, sortByColumn,
            startDate, endDate, statuses,
            (success, data, error) => {
                if (success) {
                    const url = window.URL.createObjectURL(data);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `QuaraFinance_${new Date().toISOString().replaceAll('-', '').substring(0, 8)}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
            });
    }



    return <>
        <Card className='p-5'>
            <Col>
                <div className='d-flex flex-column flex-md-row justify-content-between'>
                    <div>
                        <h4>{t('common.orders')}</h4>
                    </div>

                    <SearchInput className='d-flex' placeholder={t('orders.search-bar-placeholder')} onChange={(e) => setSearchString(e.target.value)} />
                </div>
                <div className='mt-4 d-flex flex-row justify-content-end'>
                    <Row>
                        <Col>
                            <a onClick={() => { setShowFilters(!showFilters) }} className="btn btn-outline-dark">
                                <div className='fixed-plugin-button d-flex flex-row justify-content-start'>
                                    <FilterIcon className='me-2' />
                                    <div>{t('common.filter')}</div>
                                </div>
                            </a>
                        </Col>
                        <Col>
                            <Button onClick={downloadOrders} className='btn bg-grey-100 white' style={{ width: 'max-content' }} variant='dark'>{t('common.export-xlsx')}</Button>
                        </Col>

                    </Row>
                </div>
                <Row>
                    <Col>
                        <div className=' text-center pb-2 ms-3' style={{
                            width: '128px',
                            borderBottom: '1px solid',
                            borderColor: '#f6bb50 !important',
                            color: '#f6bb50',
                        }}>
                            {`${t('common.count')} (${orders.totalOrders})`}
                        </div>
                        <div className='border-top mb-3'></div>
                        <div className='d-flex flex-row mb-2 flex-wrap gap-3'>
                            {!!filtersState?.amount?.length && <div className='d-flex flex-row bg-grey-02 border rounded'>
                                <div className='p-1 m-auto text-black'> {`${t('common.amount')}: ${filtersState.amount[0]} - ${filtersState.amount[1]}`}</div>
                                <a className='btn my-auto' onClick={() => { dispatchFilter({ type: 'amount', payload: [] }) }}>X</a>
                            </div>}

                            {!!filtersState?.dates?.length && <div className='d-flex flex-row bg-grey-02 border rounded'>
                                <div className='p-1 m-auto text-black'> {`${t('common.selected-dates')} ${filtersState.dates[0]} - ${filtersState.dates[1] ?? ''}`}</div>
                                <a className='btn my-auto' onClick={() => { dispatchFilter({ type: 'date', payload: [] }) }}>X</a>
                            </div>}

                            {!!filtersState?.states?.length && <div className='d-flex flex-row bg-grey-02 border rounded'>
                                <div className='p-1 m-auto text-black'> {`${t('common.status')}: ${filtersState.states}`}</div>
                                <a className='btn my-auto' onClick={() => { dispatchFilter({ type: 'status', payload: [] }) }}>X</a>
                            </div>}
                        </div>
                        <DataTable
                            columns={columns}
                            data={orders.MerchantOrderListItemRs}
                            customStyles={TableStyles}
                            pagination
                            paginationComponent={CustomMaterialPagination}
                            paginationTotalRows={orders?.totalOrders}
                            paginationServer
                            pointerOnHover={true}
                            sortIcon={<ArrowIcon className='mx-1' style={{ width: '10px' }} />}
                            sortServer
                            onSort={handleSort}
                            onChangePage={(page: number, totalRows: number) => {
                                setPageNumber(page);
                            }}
                            onChangeRowsPerPage={(currentRowsPerPage: number, currentPage: number) => {
                                setPageNumber(currentPage);
                                setPageSize(currentRowsPerPage);
                            }}
                            onRowClicked={(row) => { navigate(`/orders/details/${row.orderId}`) }}
                        />
                    </Col>
                </Row>
            </Col>
        </Card>
        <div className={`fixed-plugin z-index-3 ` + (showFilters ? 'show' : '')}>
            <OrdersFiltersPanel setShowFilters={setShowFilters} filterDispatcher={dispatchFilter} selectedFilters={filtersState} ordersAggregates={ordersAggregates} />
        </div>
    </>;
}
export const getYearDiffWithMonth = (startDate: Date, endDate: Date) => {
    const ms = endDate.getTime() - startDate.getTime();
  
    const date = new Date(ms);
    return Math.abs(date.getUTCFullYear() - 1970);
}

// how to use this function 
// parseJwt( JWT Token ) => returns object
export const parseJwt =  (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

// how to use this function (we using it for search inputs)
// delayInput(1000, () => {  fill inputs here  }, () => {  initialization here (optional)  })
export const delayInput = (ms, callBack, initialization?: () => void) => {
    let filterTimeout

    clearTimeout(filterTimeout)

    filterTimeout = setTimeout(() => {
        callBack();
    }, ms)
}
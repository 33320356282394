import { DebounceInput } from "react-debounce-input";
import { useTranslation } from "react-i18next";
import { SearchIcon } from "../../utils/Icons";

interface IProps {
    value?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    className?: string;
}


export const SearchInput: React.FC<IProps> = ({
    value,
    onChange,
    placeholder,
    className,
    ...props
}) => {
    const [_, i18next] = useTranslation();

    const style = {
        icon: {
            transform: i18next.language == 'ar' ? 'translateY(50%) translateX(-200%)' : 'translateY(50%) translateX(200%)',
        }
    };
    return (
        <div className={"position-relative " + className}>
            <SearchIcon className="position-absolute top-50 translate-middle-y" style={{...style.icon, left: '12px'}} />
            <DebounceInput
                debounceTimeout={300}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                type='search'
                className="form-control search-input"
                {...props}
            />
        </div>
    );
};

import { FC, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DatePicker, { DateObject } from "react-multi-date-picker";
import './customDatePickerStyles.scss';
import gregorian_ar from "react-date-object/locales/gregorian_ar";
import gregorian_en from "react-date-object/locales/gregorian_en";

interface IDatePickerProps {
  maxDate?: Date;
  dateFormat?: string;
  onChange: (dateValue: DateObject | DateObject[]) => void;
  dateValue: any;
  placeholder?: string;
  range?: boolean
}

export const CustomDatePicker: FC<IDatePickerProps> = ({
  maxDate = new Date(),
  onChange,
  dateValue,
  placeholder = "",
  range = false
}): JSX.Element => {
  const datePickerRef = useRef();
  const [shouldCloseCalendar, setCloseFlag] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<DateObject | DateObject[]>();
  const handleChange = (chosenDate: DateObject) => {
    setSelectedDate(chosenDate);
  };
  const handleCalendarClose = (): boolean => shouldCloseCalendar;
  const handleOpenDatePicker = () => setCloseFlag(false);
  const handleSelectClick = () => {
    onChange(selectedDate);
    setCloseFlag(true);
  };
  const handleCloseClick = () => {
    setCloseFlag(true);
  };

  const [t, i18next] = useTranslation();


  useEffect(() => {
    if (datePickerRef?.current && shouldCloseCalendar) {
      (datePickerRef.current as any).closeCalendar();
    }
  }, [shouldCloseCalendar]);

  useEffect(() => {
    if(dateValue.length === 0) {
      setSelectedDate(undefined);
    }
  }, [dateValue]);

  return (
    <DatePicker
      ref={datePickerRef}
      onOpen={handleOpenDatePicker}
      onClose={handleCalendarClose}
      value={selectedDate}
      onChange={handleChange}
      maxDate={maxDate}
      editable={false}
      arrow={false}
      fixMainPosition
      placeholder={placeholder}
      range={range}
      locale={i18next.language === 'ar' ? gregorian_ar : gregorian_en}
    >
      <Col>
        {selectedDate && !range && <div className=" text-black">{t('common.selected-date')} {(selectedDate as DateObject)?.format('dd MMM YYYY').toString()}</div>}
        {selectedDate && range && <div className=" text-black small">{t('common.selected-date')} {
        (selectedDate as DateObject [])?.map(d => {
          return d.format('dd MMM YYYY').toString()
        }).reduce((acc, curr) => {
          return acc + ', ' + curr
        })
        
        }</div>}
        <Row className="mt-3">
          <Col>
            <button className="btn btn-dark" disabled={!selectedDate} onClick={handleSelectClick}>{t('common.submit')}</button>
          </Col>
          <Col>
            <button className="btn btn-light" onClick={handleCloseClick}>{t('common.cancel')}</button></Col>
        </Row>
      </Col>
    </DatePicker>
  );
};

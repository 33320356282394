import React from 'react';
import { useState } from 'react';
import { loginAPI } from './hook/loginApi';
import background from "../../assets/images/curved-6.jpg";
import { useTranslation } from 'react-i18next';
import { LogoSmallIcon } from '../../utils/Icons';
import { isEmail } from '../../utils/Validation';
import PasswordInput from '../../components/PasswordInput/PasswordInput';
import { Counter } from '../../components/Counter/Counter';

export const Login: React.FC = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorPassword, setErrorPassword] = useState(false);
    const [blocked, setBlocked] = useState(false);
    const [counter, setCounter] = React.useState(0);

    const buttonHandler = () => {
        loginAPI({email: email, password: password}, (success, errorMsg) => {
            if(success) window.location.reload();
            else if (errorMsg?.message === "multiple wrong password attempts") {
                setBlocked(true);
                setCounter((60 * 5) - 1);
            }
            else setErrorPassword(true);
        });
    };
    const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setEmail(val);
        isEmail(val) ? setErrorEmail(false) : setErrorEmail(true);
    }

    return (
        <section>
            <div className="page-header min-vh-100">
            
                <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="oblique position-absolute top-0 h-100 d-md-block d-none me-n8" style={{ width: '30%' }}>
                            <div className="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" style={{ backgroundImage: `url(${background})` }}>
                            </div>
                            <img style={{width: '90%', margin: 'auto', position: 'absolute', inset: 0}} src={require('../../assets/icons/logo-with-words.png')} alt="" />
                        </div>
                    </div>
                    <div className="col-md-8 d-flex flex-column mx-auto">
                    
                        <div className="card card-plain mt-md-6">
                            <div className="card-header  d-flex justify-content-center d-md-none">
                                <LogoSmallIcon width={"75%"} />
                            </div>
                            <div className="card-header pb-0 text-left bg-transparent d-flex justify-content-between align-items-center">
                                <h3 className="fw-normal text-dark d-inline-block">{t("login.title")}</h3>
                                <a href="create_account" className="btn btn-dark">{t("buttons.create_account")}</a>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6 d-flex flex-column mx-auto form-body">
                                        <label>{t('inputs.email')}</label>
                                        <div className="mb-1">
                                            <input type="email" className={"form-control " + (errorEmail ? 'is-invalid' : '')} placeholder={t('inputs.email_placeholder')} aria-label="Email" aria-describedby="email-addon" onChange={onChangeEmail}/>
                                        </div>
                                        {errorEmail && <div className="invalid-feedback d-block mt-0 mb-4">{t('errors.invalid_email')}</div>}
                                    </div>
                                    <div className="col-md-6 d-flex flex-column mx-auto form-body">
                                        <label>{t('inputs.password')}</label>
                                        <PasswordInput 
                                            name='password'
                                            className={"form-control password " + (errorPassword ? 'is-invalid' : '')}                                            
                                            placeholder={t('inputs.password_placeholder')}
                                            onChange={e => {
                                                setErrorPassword(false);
                                                setPassword(e.target.value)
                                            }}
                                        />
                                        {errorPassword && <div className="invalid-feedback d-block mt-0 mb-4">{t('errors.invalid_password')}</div>}
                                    </div>
                                    {blocked && (
                                        <div className="col-md-12 d-flex flex-column mx-auto form-body mt-3">
                                            <div className="invalid-feedback d-block mt-0 mb-4">{t('errors.blocked')} <span className='text-body fw-bold'>{t('login.retry_in')} <Counter seconds={counter} onTimeChange={(time) => {setCounter(time); setBlocked(time ? true : false);}} /></span></div>
                                        </div>
                                    )}   
                                    <div className="text-center d-flex flex-column flex-md-row mt-4 justify-content-between align-items-center">
                                            <a href="forget_password" className="text-warning text-sm font-weight-bold">{t('buttons.forgot_password')}</a>
                                        <button type="button" className="btn btn-warning px-7 py-3" onClick={buttonHandler} disabled={errorEmail || errorPassword || email.length < 3}>{t('buttons.login')}</button>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
                </div>
            </div>
        </section>
    );
}

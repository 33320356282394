import React from 'react';

interface IProps {
  seconds: number;
  onTimeChange: (seconds: number) => void;
}

export const Counter: React.FC<IProps> =
  ({seconds, onTimeChange}) => {
    const [counter, setCounter] = React.useState(seconds);

    React.useEffect(() => {
        const timer =
          counter > 0 && setInterval(() => {
            setCounter(counter - 1);
            onTimeChange(counter - 1);
          }, 1000);
        return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counter]);

    return <>{Math.trunc(counter / 60)}:{(counter % 60) < 10 ? '0' + (counter % 60) : (counter % 60)}</>;
  }


/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../hooks/hooks';
import { listItems } from '../../hooks/MenuList';



export const Sidebar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [t] = useTranslation();
  const {sidebar} = useAppSelector(state => state.settings);

    return (
        <>
            <aside className={"sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 mt-0 " + (sidebar === "show" ? 'fixed-start' : "d-none")} id="sidenav-main">
              <div className="collapse navbar-collapse  w-auto " id="sidenav-collapse-main">
                <ul className="navbar-nav">
                  {
                    listItems.map((item, index) => 
                      <li className="nav-item" key={index}>
                        <a className={ (location.pathname === item.url ? 'active' : '') + " nav-link"} onClick={() => navigate(item.url)}>
                          {item.icon}
                          <span className="nav-link-text ms-1">{t(item.name)}</span>
                        </a>
                      </li>
                    )
                  }
                </ul>
              </div>
            </aside>
        </>
    );
}

import API from "../../../hooks/api";
import {OrderDetail, calculateTotalAmountPerItem} from "./ScreenHelper";

export const orderDetailApi = async (orderId: string, callBack:(success: boolean, data: OrderDetail,
        errorMsg: string | null)=> void = ()=>  {}) => {
    const url = `order/merchant/orders/${orderId}/details`;
    await  API.get(url).then((res)=>{
        callBack(true, calculateTotalAmountPerItem(res.data), null);
    }).catch((err)=>{
        callBack(false, null, err);
    });
};




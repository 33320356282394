import { config } from 'process';
import qs from 'qs';
import API from '../../../hooks/api';

export interface IOrderListData {
    creationDate: string;
    orderId: string;
    orderReferenceId: string;
    loanId: string;
    customerEnglishFirstName: string;
    customerEnglishLastName: string;
    customerEnglishSecondName: string;
    customerEnglishThirdName: string;
    customerArabicFamilyName: string;
    customerArabicFirstName: string;
    customerArabicFatherName: string;
    customerArabicGrandFatherName: string;
    status: string;
    orderSubStatus: string;
    amount: string;
}

export interface IOrderPagination {
    currentPage: number;
    pageCount: number;
    pageSize: number;
    totalOrders: number;
    MerchantOrderListItemRs: IOrderListData[];
}

export const orderListAPI = async (page: number, size: number, searchString?: string,
    minAmount?: number, maxAmount?: number, sortBy?: string, sortByColumn?: string,
    startDate?: string, endDate?: string, statuses?: string[],
    callBack: (success: boolean, data: IOrderPagination, errorMsg: string | null) => void = () => { }
) => {
    const url = `order/merchant/dashboard/orderList`;
    await API.get(url, { params: { page, size, searchString, minAmount, maxAmount, sortBy, sortByColumn, startDate, endDate, statuses }, paramsSerializer: (params) => {
        return qs.stringify(params, {arrayFormat: 'comma'})
     }})
        .then((res) => {
            callBack(true, res.data, null);
        }).catch((err) => {
            callBack(false, null, err);
        });
}

export const exportOrderListAPI = async (page: number, size: number, searchString?: string,
    minAmount?: number, maxAmount?: number, sortBy?: string, sortByColumn?: string,
    startDate?: string, endDate?: string, statuses?: string[],
    callBack: (success: boolean, data: IOrderPagination, errorMsg: string | null) => void = () => { }
) => {
    const url = `order/merchant/dashboard/orderList/exportFile`;
    await API.get(url, { responseType: 'blob', params: { page, size, searchString, minAmount, maxAmount, sortBy, sortByColumn, startDate, endDate, statuses }, paramsSerializer: (params) => {
        return qs.stringify(params, {arrayFormat: 'comma'})
     }})
        .then((res) => {
            let url = window.URL.createObjectURL(res.data);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'orderList.csv';
            a.click();
        }).catch((err) => {
            callBack(false, null, err);
        });
}

export interface ICustomersFilters {
    page: number;
    size: number;
    searchString?: string;
    sortBy?: string; 
    sortByColumn?: string;
}

export interface ICustomerOrdersFilters {
    page: number;
    size: number;
    sortBy?: string; 
    sortByColumn?: string;
}

export interface ICustomersListData {
    totalOrderAmount: string;
    totalOrderCount: string;
    lastOrderDate: string;
    customerId: string;
    customerEnglishFirstName: string;
    customerEnglishLastName: string;
    customerEnglishSecondName: string;
    customerEnglishThirdName: string;
    customerArabicFamilyName: string;
    customerArabicFirstName: string;
    customerArabicFatherName: string;
    customerArabicGrandFatherName: string;
}

export interface ICustomersPagination {
    currentPage: number;
    pageCount: number;
    pageSize: number;
    totalCustomers: number;
    customerList: ICustomersListData[];
}

export const columns = (t, navigate): Array<any> => {
    return [
        {
            id: 'customerEnglishFirstName',
            name: t('common.customer-name'),
            selector: row => row?.customerEnglishFirstName ?? '',
            sortable: true,
        },
        {
            id: 'totalOrderAmount',
            name: t('customers.total_orders_amount'),
            selector: row => row?.totalOrderAmount,
            sortable: true,
        },
        {
            id: 'totalOrderCount',
            name: t('customers.orders_no'),
            selector: row => row?.totalOrderCount,
            sortable: true,
        },
        {
            id: 'lastOrderDate',
            name: t('customers.last_order_at'),
            selector: row => row?.lastOrderDate,
            sortable: true,
        },
        {
            name: t('common.action'),
            selector: row => row.status,
            cell: row => <div className='d-flex flex-row justify-content-center'>
                <button className={"btn btn-link"} onClick={() => navigate(`/customers/orders/${row.customerId}`)}>{t('customers.show_orders')}</button>
            </div>,
            sortable: false,
            style: {
                justifyContent: 'center',
            }

        },

    ]
}
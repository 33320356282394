import { resources } from './../locales/index';
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

i18next
  .use(initReactI18next)
  .use(LanguageDetector)  
  .init({
    resources,
    saveMissingPlurals: false,
    fallbackLng: "en",
    debug: process.env.I18NEXT_DEBUG === "true" ? true : false,
    defaultNS: 'main',
    supportedLngs: ['en', 'ar'],
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["querystring", "localStorage"],
      lookupQuerystring: "lang",
    },
  });
export default i18next;
import MAIN_AR from './ar/main.json'
import MAIN_EN from './en/main.json'
import ERRORS_AR from './ar/errors.json'
import ERRORS_EN from './en/errors.json'

// "Inline" English and Arabic translations. 
// We can localize to any language and any number of languages.
export const resources = {
    en: {
        main: MAIN_EN,
        errors: ERRORS_EN,
    },
    ar: {
        main: MAIN_AR,
        errors: ERRORS_AR,
    },
}
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Auth } from '../routers/Auth.router';
import { useTranslation } from 'react-i18next';

export const AuthLayout: React.FC = () => {
    const [_, i18n] = useTranslation();

    const toggleLanguage = (key: "en" | "ar") => {
        i18n.changeLanguage(key);
        window.location.reload();
    }
    

    return (
        <> 
            <main className="main-content  mt-0">
                <div className="container-fluid auth-navbar-lang" dir={i18n.language === 'en' ? "rtl" : "ltr"}>
                    <a className={"btn-link fw-bold px-4 " + (i18n.language === 'ar' ? 'text-black' : 'text-body')} onClick={() => toggleLanguage('ar')}>العربية</a>
                    <a className={"btn-link fw-bold " + (i18n.language === 'en' ? 'text-black' : 'text-body')}  onClick={() => toggleLanguage('en')}>English</a>
                </div>
                <Auth/>
            </main>
        </>
    );
}


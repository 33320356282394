import API from '../../../hooks/api';
import { localStorageHelper } from '../../../hooks/localStorageHelper';
import { userLoginData } from './ScreenHelper';

export const loginAPI = async (
        data: userLoginData,
        callBack: (success: boolean, errorMsg: string | any | null) => void = () => {}
    ) => {
    await API.post("/connect/merchant/login", data)
    .then((res) => {
        localStorageHelper.store('profile', res.data)
        callBack(true, null);
    }).catch((err) => {
        callBack(false, err);
    });
}

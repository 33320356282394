import React, { useState } from 'react';
import { registerAPI } from './hook/API';
import background from "../../assets/images/curved-6.jpg";
import { useTranslation } from 'react-i18next';
import { DEFAULT_DATA, userLoginData } from './hook/ScreenHelper';
import { store } from '../../redux/store';
import { setErrorMsg } from '../../redux/reducers/settingsSlice';
import { ErrorIcon, LogoSmallIcon, SuccessIcon } from '../../utils/Icons';
import { isEmail } from '../../utils/Validation';
import { useNavigate } from 'react-router-dom';

export const CreateAccount: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [values, setValues] = useState<userLoginData>(DEFAULT_DATA);
    const [wrongPhone, setWrongPhone] = useState(true);
    const [wrongEmail, setWrongEmail] = useState(true);

    const buttonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (isEmail(values.contactEmail)) {
            registerAPI(values, (success) => {
                if (!success) {
                    store.dispatch(setErrorMsg({
                        icon: <ErrorIcon />,
                        title: t('errors.something_went_wrong'),
                    }))
                } else {
                    store.dispatch(setErrorMsg({
                        icon: <SuccessIcon />,
                        title: t('create_account.success_msg_title'),
                        message: t('create_account.success_msg_desc'),
                        acceptBtnTitle: t('buttons.exit'),
                        acceptBtnAction: () => navigate('/login')    
                    }));
                }
            });
        }
    };


    const onChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
        const phoneValue = e.target.value;
        if (!isNaN(+phoneValue))
            if(phoneValue.charAt(0) === '5' || phoneValue.charAt(0) === '0'  || phoneValue === ""){
                if (e.target.value.length > 9 && phoneValue.charAt(0) === '5') return;
                else if (e.target.value.length > 10 && phoneValue.charAt(0) === '0') return;
                else {
                    setWrongPhone(false);
                    setValues({...values, contactNumber: e.target.value})
                }
            }
    }



    return (
        <section>
            <div className="page-header min-vh-100">
                <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="oblique position-absolute top-0 h-100 d-md-block d-none me-n8" style={{ width: '30%' }}>
                            <div className="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" style={{ backgroundImage: `url(${background})` }}>
                            </div>
                            <img style={{width: '90%', margin: 'auto', position: 'absolute', inset: 0}} src={require('../../assets/icons/logo-with-words.png')} alt="" />
                        </div>
                    </div>
                    <div className="col-md-8 d-flex flex-column mx-auto">
                        <div className="card card-plain mt-md-6">
                            <div className="card-header  d-flex justify-content-center d-md-none">
                                <LogoSmallIcon width={"75%"} />
                            </div>
                            <div className="card-header pb-0 text-left bg-transparent">
                                <h3 className="fw-normal text-dark">{t("create_account.title")}</h3>
                                <h6 className="fw-normal text-dark">{t("create_account.description")}</h6>

                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="form-body col-md-6 d-flex flex-column ">
                                        <label>{t("create_account.inputs.business_name")}*</label>
                                        <div className="mb-3 d-flex">
                                            <input type="text" className="form-control" placeholder={t("create_account.inputs.business_name_placeholder")} onChange={e => setValues({...values, businessName: e.target.value})} value={values.businessName}/>
                                        </div>
                                    </div>
                                    <div className="form-body col-md-6 d-flex flex-column ">
                                        <label>{t("create_account.inputs.business_cr")}*</label>
                                        <div className="mb-3 d-flex">
                                            <input type="text" className="form-control" placeholder={t("create_account.inputs.business_cr_placeholder")} onChange={e => setValues({...values, businessCr: e.target.value})} value={values.businessCr}/>
                                        </div>
                                    </div>
                                    <div className="form-body col-md-6 d-flex flex-column ">
                                        <label>{t("create_account.inputs.business_contact_name")}*</label>
                                        <div className="mb-3 d-flex">
                                            <input type="text" className="form-control" placeholder={t("create_account.inputs.business_contact_name_placeholder")} onChange={e => setValues({...values, contactName: e.target.value})} value={values.contactName}/>
                                        </div>
                                    </div>
                                    <div className="form-body col-md-6 d-flex flex-column ">
                                        <label>{t("create_account.inputs.business_contact_phone")}*</label>
                                        <div className="mb-3 d-flex" dir="ltr">
                                            <div className="form-control w-auto mx-2 d-flex align-items-center">
                                                <img src={require('../../assets/images/flag_sa.jpg')} className="navbar-brand-img h-75 me-2" alt="main_logo" />
                                                +966
                                            </div>
                                            <input type="text" className="form-control" placeholder={t('inputs.mobile_number_placeholder')} 
                                            onChange={onChangePhone} value={values.contactNumber}/>
                                        </div>
                                    </div>
                                    <div className="form-body col-md-6 d-flex flex-column ">
                                        <label>{t("create_account.inputs.business_contact_email")}*</label>
                                        <div className="mb-3 d-flex">
                                            <input type="text" className="form-control" placeholder={t("create_account.inputs.business_contact_email_placeholder")} onChange={e => {
                                                setWrongEmail(!isEmail(e.target.value))
                                                setValues({...values, contactEmail: e.target.value})
                                            }} value={values.contactEmail}/>
                                        </div>
                                    </div>
                                    <div className="text-center d-flex flex-column flex-md-row mt-4 justify-content-between">
                                        <a href="login" className="btn btn-secondary px-7 py-3">{t("buttons.cancel")}</a>
                                        <button 
                                            type="button" 
                                            className="btn btn-warning px-7 py-3" 
                                            onClick={buttonHandler}
                                            disabled={values.businessName === '' || values.businessCr === '' || values.contactName === '' || wrongEmail || wrongPhone }
                                        >
                                            {t("buttons.register")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
                </div>
            </div>
        </section>
    );
}


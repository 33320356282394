import API from '../../../hooks/api';

export interface IOrdersAggregates {
    minOrderAmount: number,
    maxOrderAmount: number,
    orderStatusCount: {
        CANCELLED: number,
        UNDER_CANCEL: number,
        PENDING: number,
        DECLINED: number
    }
}

export const ordersAggregatesAPI = async (
    callBack: (success: boolean, data: IOrdersAggregates, errorMsg: string | null) => void = () => { }
) => {
    const url = `order/merchant/dashboard/amount/aggregate`;
    await API.get(url)
        .then((res) => {
            callBack(true, res.data, null);
        }).catch((err) => {
            callBack(false, null, err);
        });
}

import { t } from "i18next";
import { CheckmarkIcon, CloseIcon } from "../../../../utils/Icons";

export interface userLoginData {
    email: string;
    phone: string;
    password?: string;
}

export type strengthType = "Insufficient" | "Weak" | "Medium" | "Great";

export const getPasswordStrength = (password: string): strengthType => {
    const lowPassRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(.{6,})/;
    const mediumPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#$%^&*_\-+=`|\\(){}[\]:"<>,.?/])(.{7,})/;
    const strongPasswordRegex =
      /^(?=(.*[a-z]){2,})(?=(.*[A-Z]){2,})(?=.*[0-9])(?=.*[~!@#$%^&*_\-+=`|\\(){}[\]:"<>,.?/])(.{9,})/;
    if (password.match(strongPasswordRegex)) {
      return "Great";
    } else if (password.match(mediumPasswordRegex)) {
      return "Medium";
    } else if (password.match(lowPassRegex)) {
      return "Weak";
    }
    return "Insufficient";
  };
export interface validationItems {
    uppercase: boolean;
    lowercase: boolean;
    number: boolean;
    sign: boolean;
    length: boolean;
}

export const validationUL = (validation: validationItems, passwordStrength: string = ""): JSX.Element => {
    return (
        <ul className='d-inline-block p-0 list-inline'>
            <li style={{color: validation?.uppercase ? "#1F865A" : '', marginBottom: '0.6rem' }}>
                <>
                    {!validation?.uppercase ? <CloseIcon /> : <CheckmarkIcon />}  
                    {passwordStrength === "Great" ? t('update_password.validation.uppercase_great') : t('update_password.validation.uppercase')}
                </>
            </li>
            <li style={{color: validation?.lowercase ? "#1F865A" : '', marginBottom: '0.6rem' }}>
                <>
                    {!validation?.lowercase ? <CloseIcon /> : <CheckmarkIcon />}  
                    {passwordStrength === "Great" ? t('update_password.validation.lowercase_great') : t('update_password.validation.lowercase')}
                </>
            </li>
            <li style={{color: validation?.number ? "#1F865A" : '', marginBottom: '0.6rem' }}>
                <>
                    {!validation?.number ? <CloseIcon /> : <CheckmarkIcon />}  
                    {
                        (passwordStrength === "Great") ?
                            t('update_password.validation.number_great')
                        : t('update_password.validation.number')
                    }
                </>
            </li>
            <li style={{color: validation?.length ? "#1F865A" : '', marginBottom: '0.6rem' }}>
                <>
                    {!validation?.length ? <CloseIcon /> : <CheckmarkIcon />}  
                    {
                        (passwordStrength === "Great") ? t('update_password.validation.length_great') :
                        (passwordStrength === "Medium") ? t('update_password.validation.length_medium') :
                        t('update_password.validation.length')
                    }
                </>    
            </li>
            {(passwordStrength === "Great" || passwordStrength === "Medium") &&
                <li style={{color: validation?.sign ? "#1F865A" : '', marginBottom: '0.6rem' }}>
                    <>
                        {!validation?.sign ? <CloseIcon /> : <CheckmarkIcon />}  
                        {t('update_password.validation.special')}
                    </> 
                </li>
            }
        </ul>
    );
}
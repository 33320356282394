import React, { useEffect } from 'react';
import { Main } from '../routers/Main.router';
import { Sidebar } from './Sidebar/Sidebar';
import { RightSidebar } from './RightSidebar/RightSidebar';
import { Navbar } from './Navbar/Navbar';
import { Footer } from './Footer/Footer';
import { useTranslation } from 'react-i18next';
import AutoLogout from '../pages/AutoLogout/AppLogout';
import { DashboardIcon } from '../utils/Icons';

export const MainLayout: React.FC = () => {
    const { i18n } = useTranslation();

    useEffect(() => {
        document.body.className = (i18n.language === "en") ? 'g-sidenav-show bg-gray-100 ltr' : 'g-sidenav-show bg-gray-100 rtl';
    }, [])
    return (
        <AutoLogout>
            <div className="sidenav-overlay">
                <Navbar />
            </div>
            <Sidebar />
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
                {/* <Navbar /> */}
                <div className="container-fluid py-4">
                    <Main />
                    <Footer />
                </div>
            </main>
            {/* <RightSidebar /> */}
        </AutoLogout>
    );
}


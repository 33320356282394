import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { CreateAccount } from "../pages/CreateAccount/CreateAccount";
import { Login } from "../pages/login/Login";
import { ForgetPassword } from "../pages/ResetPassword/ForgetPassword/ForgetPassword";
import { OTP } from "../pages/ResetPassword/OTP/OTP";
import { UpdatePassword } from "../pages/ResetPassword/UpdatePassword/UpdatePassword";


export const Auth: FC = () => {
  return (
    <Routes>
        <Route path='/create_account' element={<CreateAccount />} />
        <Route path='/forget_password/update_password' element={<UpdatePassword />} />
        <Route path='/forget_password/verify' element={<OTP />} />
        <Route path='/forget_password' element={<ForgetPassword />} />
        <Route path='/login' element={<Login />} />
        <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

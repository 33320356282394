import MultiRangeSlider, { ChangeResult } from "multi-range-slider-react";
import { FC } from "react";
import "./custom.css";

interface Props {
    min?: number | string;
    max?: number | string;
    step?: number | string;
    minValue?: number | string;
    maxValue?: number | string;
    baseClassName?: string;
    className?: string;
    style?: React.CSSProperties;
    ruler?: boolean | string;
    label?: boolean | string;
    subSteps?: boolean | string;
    stepOnly?: boolean | string;
    preventWheel?: boolean | string;
    labels?: string[];
    minCaption?: string;
    maxCaption?: string;
    barLeftColor?: string;
    barRightColor?: string;
    barInnerColor?: string;
    thumbLeftColor?: string;
    thumbRightColor?: string;
    onInput?: (e: ChangeResult) => void;
    onChange?: (e: ChangeResult) => void;
};

export const CustomMultiRangeSlider: FC<Props> = ({
    min = 0,
    max = 100,
    step = 1,
    minValue = 0,
    maxValue = 100,
    minCaption = "",
    maxCaption = "",
    onChange = () => {},
}: Props): JSX.Element => {

    return (
        <MultiRangeSlider
        min={min}
        max={max}
        minValue={minValue}
        maxValue={maxValue}
        onChange={onChange}
        ruler={false}
        label={false}
        maxCaption={maxCaption}
        minCaption={minCaption}
        />
        );
}
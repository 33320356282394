import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../../hooks/api';
import { RootState } from '../../../redux/store';
import { IOrderPagination } from '../../Orders/hooks/orderListApi';
import { ICustomersPagination } from './ScreenHelper';

export const fetchCustomers = createAsyncThunk(
    'customers/getCustomers',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const url = `order/merchant/dashboard/customerList`;
        const response = await API.get(url, {params: state.customers.filters});
        const data: ICustomersPagination = await response.data;
        return {customers: data} ;
    }
)

export const exportCustomers = createAsyncThunk(
    'customers/exportCustomers',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const url = `order/merchant/dashboard/customer/orderList/exportFile`;
        const response = await API.get(url, {responseType: 'blob',  params: state.customers.filters});
        let urlFile = window.URL.createObjectURL(response.data);
        let a = document.createElement('a');
        a.href = urlFile;
        a.download = 'customersList.csv';
        a.click();
    }
)


export const fetchCustomerOrders = createAsyncThunk(
    'customers/getCustomerOrders',
    async (customerId: string, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const url = `order/merchant/dashboard/orderList`;
        const response = await API.get(url, {params: {...state.customers.customerOrdersFilters, customerId}});
        const data: IOrderPagination = await response.data;
        return data;
    }
)
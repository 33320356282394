import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrencyText } from '../../utils/hooks';

interface IProps {
  amount: string;
  currencyDisplay?: 'code' | 'name' | 'symbol';
}


export const CurrencyText: React.FC<IProps> =
  ({amount, currencyDisplay="name"}) => {

    return (
        <div>{useCurrencyText(amount, currencyDisplay)}</div>
    );
  }


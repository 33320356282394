import API from '../../../hooks/api';
import { userLoginData } from './ScreenHelper';

export const registerAPI = async (
        data: userLoginData,
        callBack: (success: boolean, errorMsg: string | null) => void = () => {}
    ) => {
    const number = data.contactNumber.charAt(0) === '0' ? data.contactNumber.slice(1) : data.contactNumber;
    await API.post("/order/merchant/contactInfo", {...data, contactNumber: `+966${number}`})
    .then((res) => {
        console.log(res)
        callBack(true, null);
    }).catch((err) => {
        callBack(false, err);
    });
}

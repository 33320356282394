import { latLng2Tile } from 'google-map-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import zArrowGreen from '../../assets/icons/z-arrow-green.svg';
import zArrowRed from '../../assets/icons/z-arrow-red.svg';
import { CurrencyText } from '../CurrencyText/CurrencyText';

interface IProps {
  Icon: React.FunctionComponent<React.SVGProps<
    SVGSVGElement
  >>;
  amount: string;
  title: string;
  changePercent: string;
  timePeriod: string;
  currency?: boolean;
}


export const AggregatesCard: React.FC<IProps> =
  ({ Icon, amount, title, changePercent, timePeriod, currency }) => {
    const [t, i18next] = useTranslation();

    const numberChangePercent = Number(changePercent);
    changePercent = Number(changePercent ?? 0).toFixed(2);

    const currencyFormatOptions: Intl.NumberFormatOptions = { style: 'currency', currency: 'SAR', currencyDisplay: i18next.language == 'ar' ? 'name' : 'code' };
    const currencyFormatter = new Intl.NumberFormat(i18next.language, currencyFormatOptions);

    const styles = {
      icon: {
        width: '60px',
        height: '60px',
      },
      percentage: {
        color: numberChangePercent > 0 ? '#36B37E' : '#E22A1F',
      },
    };

    return (
      <div className="card container p-4 col">

        <div className="d-flex align-items-center justify-content-between">
          <div className='d-flex align-items-center'>
            <Icon className="mb-3 me-3" style={styles.icon}></Icon>
            <div className="h5 bold" >{currency ? <CurrencyText amount={amount} currencyDisplay={i18next.language === 'en' ? 'code' : 'name'}></CurrencyText> : amount ?? 0}</div>
          </div>
          <i className="fas fa-ellipsis-v  text-gray cursor-pointer fs-4 dropdown" data-bs-toggle="dropdown">
              <ul className="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5">
                <li><a className="dropdown-item border-radius-md" >{t('common.pending')}</a></li>
                <li><a className="dropdown-item border-radius-md" >{t('common.completed')}</a></li>
                <li><a className="dropdown-item border-radius-md" >{t('common.declined')}</a></li>
                <li><a className="dropdown-item border-radius-md" >{t('common.cancelled')}</a></li>
              </ul>
          </i>
        </div>

        <div className="row row-cols-1 ">
          <div className="col text-muted h6">{title}</div>
        </div>
        <div className="d-flex">
          <span className='me-4'>{timePeriod}</span>
          <div className="content">
            <img style={i18next.language == 'ar' ? { transform: 'scaleX(-1)' } : {}} src={numberChangePercent > 0 ? zArrowGreen : zArrowRed} width="24px" height='24px' />
            <span className="col mx-1" style={styles.percentage}>
              {changePercent}%
            </span>
          </div>
        </div>
      </div>
    );
  }


import React, { ForwardRefRenderFunction, InputHTMLAttributes } from 'react';
import { EyeIcon, EyeSlashIcon } from '../../utils/Icons';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
}

const Input: ForwardRefRenderFunction<HTMLInputElement, InputProps> = ({ name, ...otherProps }, ref) => {
  const [showPassword, setShowPassword] = React.useState(false); 
  // create onClick handler for Eye icon
  const onClick = () => {
    setShowPassword(!showPassword);
  } 
  return (
        <div className="mb-1 d-flex position-relative">
            <input name={name} type={showPassword ? 'text' : 'password'} {...otherProps}/>
            {showPassword ? <EyeSlashIcon className='password_svg' style={{ cursor: 'pointer' }} onClick={onClick} /> : <EyeIcon className='password_svg' style={{ cursor: 'pointer' }} onClick={onClick} /> }
        </div>
    );
};

const PasswordInput = React.forwardRef(Input);

export default PasswordInput;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";


export interface Profile {
    name: string,
    company?: string | null,
}

const initialState: Profile = {
    name: 'Talal Moahmed',
    company: 'Quara finance'
}

const UserProfileSlice = createSlice({
    initialState,
    name: "user_profile",
    reducers: {
        setUserData(state, action: PayloadAction<Profile>) {
            state = action.payload;
        }
    }
});


export const { setUserData } = UserProfileSlice.actions;
export default UserProfileSlice.reducer;
import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { CustomerOrdersPage } from "../pages/Customers/CustomerOrdersPage";
import { CustomersPage } from "../pages/Customers/CustomersPage";
import { Dashboard } from "../pages/Dashboard/Dashbaord";
import { OrderDetails } from "../pages/OrderDetails/OrderDetails";
import { OrdersPage } from "../pages/Orders/OrdersPage";

export const Main: FC = () => {
  return (
    <Routes>
      <Route path='/' element={<Dashboard />} />
      <Route path='/customers/orders/:customerId'  element={<CustomerOrdersPage />} />
      <Route path='/customers' element={<CustomersPage />} />
      <Route path='/orders/details/:orderId'  element={<OrderDetails />} />
      <Route path='/orders' element={<OrdersPage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

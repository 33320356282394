import qs from 'qs';
import API from '../../../hooks/api';


export const orderExcelAPI = async (
    minAmount?: number, maxAmount?: number, sortBy?: string, sortByColumn?: string,
    startDate?: string, endDate?: string, statuses?: string[],
    callBack: (success: boolean, data: any, errorMsg: string | null) => void = () => { }
) => {
    const url = `order/merchant/dashboard/orderList/exportFile`;
    await API.get(url, { responseType: "blob", params: { minAmount, maxAmount, sortBy, sortByColumn, startDate, endDate, statuses }, paramsSerializer: (params) => {
        return qs.stringify(params, {arrayFormat: 'comma'})
     }})
        .then((res) => {
            callBack(true, res.data, null);
        }).catch((err) => {
            callBack(false, null, err);
        });
}

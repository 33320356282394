import { CustomersIcon, DashboardIcon, OrdersIcon } from "../utils/Icons";

export interface SidebarItem {
    name: string;
    url: string;
    icon: JSX.Element;
}

export const listItems : SidebarItem[] = [
    {
      name: 'common.dashboard',
      url: '/',
      icon: <DashboardIcon className='mx-3 fill' />
    },
    {
      name: 'common.orders',
      url: '/orders',
      icon: <OrdersIcon className='mx-3 stroke' />
    },
    {
      name: 'common.customers',
      url: '/customers',
      icon: <CustomersIcon className='mx-3 stroke' />
    }
];
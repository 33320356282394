interface IProps {
    percentages: number[];
    colors: { backgroundColor: string, color: string }[];
}

export const PercentageBar = ({ percentages, colors, ...props }: IProps) => {
    const roundedStartIndex = percentages.findIndex((percentage) => percentage > 0);
    percentages.reverse();
    const roundedEndIndex = (percentages.length - 1 - percentages.findIndex((percentage) => percentage > 0)) % percentages.length;
    percentages.reverse();

    return (
        <div className=" d-flex flex-row text-center text-bold" {...props}>
            {percentages.map((percentage, index) => (
                (percentage > 0) && <div
                    key={index}
                    className={`d-flex flex-column justify-content-center p-1
                    ${index === roundedStartIndex ? 'rounded-start' : ''}
                    ${index === roundedEndIndex ? 'rounded-end' : ''}
                    `}
                    style={{
                        width: `${percentage}%`,
                        minWidth: '5rem',
                        backgroundColor: colors[index].backgroundColor,
                        color: colors[index].color,
                    }}
                >
                    {percentage}%
                </div>
            ))
            }
        </div>
    );
};
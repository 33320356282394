export interface OrderDetail {
    itemCount?:                     number;
    items?:                         Item[];
    creationDate?:                  string;
    orderId?:                       string;
    orderReferenceId?:              string;
    loanId?:                        string;
    customerEnglishFirstName?:      string;
    customerEnglishLastName?:       string;
    customerEnglishSecondName?:     string;
    customerEnglishThirdName?:      string;
    customerArabicFamilyName?:      string;
    customerArabicFirstName?:       string;
    customerArabicFatherName?:      string;
    customerArabicGrandFatherName?: string;
    status?:                        string;
    orderSubStatus?:                string;
    amount?:                        string;
}

interface Item {
    id:          string;
    category:    string;
    name:        string;
    imageUrl:    string;
    description: string;
    quantity:    number;
    totalAmountPerItem:  number;
    unitPrice:   UnitPrice;
}

interface UnitPrice {
    vat:      number;
    amount:   number;
    currency: string;
}

export function calculateTotalAmountPerItem(orderDetail:OrderDetail): OrderDetail {
    console.log(orderDetail.items);
    orderDetail.items.map((item) => 
        item.totalAmountPerItem = (item.unitPrice.amount + item.unitPrice.vat) * item.quantity
    );
    console.log(orderDetail.items);
    return  orderDetail;
}


export const columns = (t, isAR) => {
    return [
    {
        name: '',
        selector: row => <img src={row?.imageUrl ? row?.imageUrl : require('../../../assets/images/no_image.png')} className="w-75 p-2 p-md-4"  alt={row?.name}/>,
        sortable: true,
    },
    {
        name: t('order_details.no'),
        selector: row => row.id,
        sortable: true,
    },
    {
        name: t('order_details.product_name'),
        selector: row => <p style={{whiteSpace: 'break-spaces' }}><small>{row?.name}</small></p>,
        sortable: true,
    },
    {
        name: t('order_details.qty'),
        selector: row => row.quantity,
        sortable: true,
    },
    {
        name: t('order_details.price_unit'),
        selector: row => isAR ?  row.unitPrice.amount + " " + t('sar')
        : t('sar') + " " + row.unitPrice.amount ,
        sortable: true,
        minWidth: '9rem'
    },
    {
        name: t('order_details.total_price'),
        selector: row => isAR ?  row.totalAmountPerItem + " " +t('sar')
            : t('sar') + " " + row.totalAmountPerItem,
        sortable: true,
        minWidth: '9rem'
    },
    ];
}
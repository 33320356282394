/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useState } from 'react';
import { submitDataAPI } from './hook/API';
import background from "../../../assets/images/curved-6.jpg";
import { useTranslation } from 'react-i18next';
import { getPasswordStrength, userLoginData, validationItems, validationUL } from './hook/ScreenHelper';
import { store } from '../../../redux/store';
import { setErrorMsg } from '../../../redux/reducers/settingsSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorIcon, LogoSmallIcon } from '../../../utils/Icons';
import { hasLowerCase, hasNumbers, hasSpicialChar, hasUpperCase } from '../../../utils/Validation';
import PasswordInput from '../../../components/PasswordInput/PasswordInput';

export const UpdatePassword: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const stateData = location.state as userLoginData;

    const [error, setError] = useState<boolean>(false);
    const [validation, setValidation] = useState<validationItems>();
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    
    const buttonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        submitDataAPI({email: stateData.email, phone: stateData.phone, password}, (success) => {
            if (success) { 
                navigate('/forget_password/verify', {state: {email: stateData.email, phone: stateData.phone} })
            } else {
                store.dispatch(setErrorMsg({
                    icon: <ErrorIcon />,
                    title: t('update_password.errors.merchant_not_found'),
                    message: t('update_password.errors.merchant_not_found_desc')
                }))
            }
        });
    };
    
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setPassword(val);
        setValidation({
            uppercase: hasUpperCase(val), 
            lowercase: hasLowerCase(val), 
            length: val.length >= 6, 
            number: hasNumbers(val), 
            sign: hasSpicialChar(val)
        });
    }

    const onChangeRepeatPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setRepeatPassword(val);
        (val === password) ? setError(false) : setError(true);
    }

    return (
        <section>
            <div className="page-header min-vh-100">
                <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="oblique position-absolute top-0 h-100 d-md-block d-none me-n8" style={{ width: '30%' }}>
                            <div className="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" style={{ backgroundImage: `url(${background})` }}>
                            </div>
                            <img style={{width: '90%', margin: 'auto', position: 'absolute', inset: 0}} src={require('../../../assets/icons/logo-with-words.png')} alt="" />
                        </div>
                    </div>
                    <div className="col-md-8 d-flex flex-column mx-auto">
                        <div className="card card-plain mt-md-6">
                            <div className="card-header  d-flex justify-content-center d-md-none">
                                <LogoSmallIcon width={"75%"} />
                            </div>
                            <div className="card-header pb-0 text-left bg-transparent">
                                <h3 className="fw-normal text-dark">{t("update_password.title")}</h3>
                                <h6 className="fw-normal text-dark">{t("update_password.description")}</h6>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6 d-flex flex-column ">
                                        <div className='row'>
                                            <div className="form-body col-md-12 d-flex flex-column w-100">
                                                <label>{t('inputs.password')}</label>
                                                <div className={getPasswordStrength(password) !== 'Insufficient' ? "mb-1" : "mb-3"}>
                                                    <PasswordInput
                                                        name='password'
                                                        className={"form-control password"}
                                                        placeholder={t('inputs.password_placeholder')}
                                                        onChange={onChange}
                                                    />
                                                </div>
                                                {getPasswordStrength(password) !== 'Insufficient' && (
                                                    <div className='row mb-4'>
                                                        <div className='col-md-3 d-flex align-items-center'>
                                                            <p className={'m-0 ' + (getPasswordStrength(password) === 'Great' ? 'password-great' : getPasswordStrength(password) === 'Medium' ? 'password-medium' : 'password-weak')}
                                                            style={{ fontSize: '0.8rem', fontWeight: 700 }}>
                                                                {getPasswordStrength(password)}
                                                            </p>
                                                        </div>
                                                        <div className='col-md-9 d-flex align-items-center'>
                                                            <div className="progress w-100">
                                                                <div 
                                                                    className={"progress-bar m-0 " + (getPasswordStrength(password) === 'Great' ? 'password-bg-great' : getPasswordStrength(password) === 'Medium' ? 'password-bg-medium' : 'password-bg-weak')}
                                                                    role="progressbar" 
                                                                    aria-label="Basic example" 
                                                                    style={{ width: (getPasswordStrength(password) === 'Great' ? '100%' : getPasswordStrength(password) === 'Medium' ? '66.6666%' : '33.3333%'), height: '3px' }} 
                                                                    aria-valuemax={100} />
                                                                    
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="form-body col-md-12 d-flex flex-column w-100">
                                                <label>{t('inputs.repeat_password')}</label>
                                                <div className="mb-1">
                                                    <PasswordInput
                                                        name='password'
                                                        className={"form-control password " + (error ? 'is-invalid' : '')}
                                                        placeholder={t('inputs.repeat_password_placeholder')}
                                                        onChange={onChangeRepeatPassword}
                                                    />
                                                </div>
                                                {error && <div className="invalid-feedback d-block">Passwords don’t match, try again.</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 d-flex flex-column align-items-end">
                                        <div  className='d-grid w-md-75'>
                                            <p className='d-inline' style={{ color: '#B5B8BD' }}>{t('update_password.password_shold_contain')}</p>
                                            {validationUL(validation, getPasswordStrength(password))}
                                        </div>
                                    </div>
                                    <div className="text-center d-flex flex-column flex-md-row mt-4 justify-content-between">
                                        <a href="login" className="btn btn-secondary px-7 py-3">{t('buttons.go_back')}</a>
                                        <button type="button" className="btn btn-warning px-7 py-3" disabled={
                                            getPasswordStrength(password) === 'Insufficient' ||
                                            password !== repeatPassword ||
                                            error
                                        } onClick={buttonHandler}>{t('buttons.continue')}</button>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
                </div>
            </div>
        </section>
    );
}


import React from 'react';
import { useState } from 'react';
import { checkDataAPI } from './hook/API';
import background from "../../../assets/images/curved-6.jpg";
import { useTranslation } from 'react-i18next';
import { onChangeEmail } from './hook/ScreenHelper';
import { store } from '../../../redux/store';
import { setErrorMsg } from '../../../redux/reducers/settingsSlice';
import { useNavigate } from 'react-router-dom';
import { ErrorIcon, LogoSmallIcon } from '../../../utils/Icons';
import { isEmail, isPhone } from '../../../utils/Validation';

export const ForgetPassword: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('1');
    
    const buttonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        checkDataAPI({email, phone: '+966' + phone}, (success) => {
            if (success) { 
                navigate('/forget_password/update_password', {state: {email,phone: '+966' + phone} })
            } else {
                store.dispatch(setErrorMsg({
                    icon: <ErrorIcon />,
                    title: t('reset_password.errors.merchant_not_found'),
                    message: t('reset_password.errors.merchant_not_found_desc')
                }))
            }
        });
    };
    

    return (
        <section>
            <div className="page-header min-vh-100">
                <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="oblique position-absolute top-0 h-100 d-md-block d-none me-n8" style={{ width: '30%' }}>
                            <div className="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" style={{ backgroundImage: `url(${background})` }}>
                            </div>
                            <img style={{width: '90%', margin: 'auto', position: 'absolute', inset: 0}} src={require('../../../assets/icons/logo-with-words.png')} alt="" />
                        </div>
                    </div>
                    <div className="col-md-8 d-flex flex-column mx-auto">
                        <div className="card card-plain mt-md-6">
                            <div className="card-header  d-flex justify-content-center d-md-none">
                                <LogoSmallIcon width={"75%"} />
                            </div>
                            <div className="card-header pb-0 text-left bg-transparent">
                                <h3 className="fw-normal text-dark">{t("reset_password.title")}</h3>
                                <h6 className="fw-normal text-dark">{t("reset_password.description")}</h6>

                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="form-body col-md-6 d-flex flex-column ">
                                        <label>{t('inputs.email')}</label>
                                        <div className="mb-1 d-flex">
                                            <input type="text" className={"form-control " + (email.length === 0 ? 'is-invalid' : '')} placeholder={t('inputs.email_placeholder')} onChange={e => onChangeEmail(e, setEmail)} />
                                        </div>
                                        {email.length === 0 && <div className="invalid-feedback d-block mt-0 mb-4">{t('errors.invalid_email')}</div>}
                                    </div>
                                    <div className="form-body col-md-6 d-flex flex-column ">
                                        <label>{t('inputs.mobile_number')}</label>
                                        <div className="mb-3 d-flex" dir="ltr">
                                            <div className="form-control w-auto mx-2 d-flex align-items-center">
                                                <img src={require('../../../assets/images/flag_sa.jpg')} className="navbar-brand-img h-75 me-2" alt="main_logo" />
                                                +966
                                            </div>
                                            <input type="text" className="form-control" placeholder={t('inputs.mobile_number_placeholder')} onChange={e => {
                                                const phoneValue = e.target.value;
                                                if (!isNaN(+phoneValue))
                                                    if(phoneValue.charAt(0) === '5' || phoneValue.charAt(0) === '0'  || phoneValue === ""){
                                                        if (e.target.value.length > 9 && phoneValue.charAt(0) === '5') return;
                                                        else if (e.target.value.length > 10 && phoneValue.charAt(0) === '0') return;
                                                        else setPhone(e.target.value);
                                                    }
                                            }} value={phone}/>
                                        </div>
                                    </div>
                                    <div className="text-center d-flex flex-column flex-md-row mt-4 justify-content-between">
                                        <a href="login" className="btn btn-secondary px-7 py-3">{t('buttons.go_back')}</a>
                                        <button type="button" className="btn btn-warning px-7 py-3" disabled={!isPhone(phone) || !isEmail(email)} onClick={buttonHandler}>{t('buttons.continue')}</button>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
                </div>
            </div>
        </section>
    );
}


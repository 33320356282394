/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { AggregatesCard } from '../../components/AggregatesCard/AggregatesCard';
import { Chart as ChartJS, registerables } from 'chart.js';


// TODO: Fix this import to work with webpack and typescript (@assets/icons/green_money.svg)
// or maybe use a global SVG loader component
import { useTranslation } from 'react-i18next';
import { aggregatesAPI, IAggregatesData } from './hook/aggregatesApi';
import { ILastTransactionsData, lastTransactionsAPI } from './hook/lastTransactionsApi';
import Moment from 'react-moment';
import { CurrencyText } from '../../components/CurrencyText/CurrencyText';
import { Card } from '../../components/Card/Card';
import { IOrderCountData, orderCountAPI } from './hook/orderCountApi';
import { LineGraph } from '../../components/LineGraph/LineGraph';
import { CompletedOrdersIcon, CustomerIcon, PendingOrdersIcon } from '../../utils/Icons';
import { StatusLabel } from '../../components/StatusLabel/StatusLabel';
import { TableStyles } from '../../utils/Constants';
import DataTable from 'react-data-table-component';
import { Pie } from 'react-chartjs-2';
import { IOrderVolumeData, orderVolumeAPI } from './hook/orderVolumeApi';
import { PercentageBar } from '../../components/PercentageBar/PercentageBar';
import { Col } from 'react-bootstrap';
import { IOrderPagination, orderListAPI } from '../Orders/hooks/orderListApi';
import { useNavigate } from 'react-router';

export const Dashboard: React.FC = () => {

    ChartJS.register(...registerables);

    const [t] = useTranslation();
    const navigate = useNavigate();
    const [aggregates, setAggregates] = useState<IAggregatesData>(null);
    const [orders, setOrders] = useState<IOrderPagination>();
    const [orderCount, setOrderCount] = useState<IOrderCountData[]>([]);
    const [orderVolume, setOrderVolume] = useState<IOrderVolumeData>(null);
    const [selectedGraphFilter, setSelectedGraphFilter] = useState<{ label: string, value: Date }>(null);
    const [selectedVolumeFilter, setSelectedVolumeFilter] = useState<{ label: string, value: Date }>(null);


    // TODO: Move these values to a helper or hook to be reused
    const lastWeekDate = new Date();
    lastWeekDate.setDate(lastWeekDate.getDate() - 7);

    const lastMonthDate = new Date();
    lastMonthDate.setDate(lastMonthDate.getDate() - 30);

    const lastSixMonthDate = new Date();
    lastSixMonthDate.setDate(lastSixMonthDate.getDate() - 30*6);

    const todaysDate = new Date();

    const filters = [
        { label: t('common.last-week'), value: lastWeekDate },
        { label: t('common.last-30-days'), value: lastMonthDate },
        { label: t('common.last-6-months'), value: lastSixMonthDate },
    ];

    useEffect(() => {

        setSelectedGraphFilter(filters[0]);

        aggregatesAPI(lastWeekDate.toISOString(), todaysDate.toISOString(), (success, data, error) => {
            if (success) {
                setAggregates(data);
            } else {
                console.log(error);
            }
        });

        // get latest 5 orders
        orderListAPI(1, 5, "",
            undefined, undefined, undefined, undefined,
            undefined, undefined, undefined,
            (success, data, error) => {
                if (success) {
                    setOrders(data);
                }
            });
        getOrderCount(filters[0]);

        getOrderVolume(filters[0]);


    }, []);


    const getOrderCount = (filter: { label: string, value: Date }) => {
        setSelectedGraphFilter(filter);

        orderCountAPI(filter.value.toISOString(), todaysDate.toISOString(), (success, data, error) => {
            if (success) {
                setOrderCount(data);
            } else {
                console.log(error);
            }
        });
    }

    const getOrderVolume = (filter: { label: string, value: Date }) => {
        setSelectedVolumeFilter(filter);

        orderVolumeAPI(filter.value.toISOString(), todaysDate.toISOString(), (success, data, error) => {
            if (success) {
                setOrderVolume(data);
            } else {
                console.log(error);
            }
        });
    }

    const columns = [
        {
            name: t('orders.order-date'),
            selector: row => row.creationDate,
            sortColumnName: 'creationDate',
        },
        {
            name: t('orders.order-reference-id'),
            selector: row => row.orderReferenceId,
            sortColumnName: 'orderReferenceId',
        },
        {
            name: t('orders.loan-id'),
            selector: row => row.loanId,
            sortColumnName: 'loanId',
        },
        {
            name: t('common.customer-name'),
            selector: row => row.customerEnglishFirstName ?? '',
            sortColumnName: 'customerEnglishFirstName',
        },
        {
            name: t('common.amount'),
            selector: row => row.amount,
            sortColumnName: 'amount',
        },
        {
            name: t('common.status'),
            selector: row => row.status,
            cell: row => <div className='d-flex flex-row justify-content-center my-1'>
                <StatusLabel status={row.status} subStatus={row?.orderSubStatus} />
            </div>,
            sortColumnName: 'status',
            style: {
                justifyContent: 'center',
            }

        },

    ];

    const ordersVolumesStyle = {
        completed: {
            backgroundColor: '#E5F9EF',
            color: '#1F865A',
        },
        pending: {
            backgroundColor: '#FFFAF0',
            color: '#A06C00'
        },
        declined: {
            backgroundColor: '#FFEEEB',
            color: '#E22A1F'
        },
        cancelled: {
            backgroundColor: '#EDEEEF',
            color: '#2C2926'
        },
        circle: {
            height: '12px',
            width: '12px',
            borderRadius: '50%',
            border: '1px solid',
        }
    };



    return (
        <>
            <div className="row">

                <div className="col-12 col-lg-4 mb-4 mb-lg-0">
                    <AggregatesCard
                        Icon={CompletedOrdersIcon}
                        amount={aggregates?.completedOrders}
                        currency={true}
                        title={t('dashboard.completed-orders')}
                        timePeriod={t('common.last-week')}
                        changePercent={aggregates?.completedOrdersRate} />
                </div>

                <div className="col-12 col-lg-4 mb-4 mb-lg-0">
                    <AggregatesCard
                        Icon={PendingOrdersIcon}
                        amount={aggregates?.pendingOrders}
                        currency={true}
                        title={t('dashboard.pending-orders')}
                        timePeriod={t('common.last-week')}
                        changePercent={aggregates?.pendingOrdersRate} />
                </div>

                <div className="col-12 col-md-4">
                    <AggregatesCard
                        Icon={CustomerIcon}
                        amount={aggregates?.customers}
                        title={t('common.customers')}
                        timePeriod={t('common.last-week')}
                        changePercent={aggregates?.customerRate} />
                </div>

            </div>

            <div className="my-5">
                <Card title={t('dashboard.orders-graph.title')} subtitle={t('dashboard.orders-graph.description')} options={
                    <>
                        <div className="dropdown float-lg-end pe-4">
                            <a className="btn btn-outline-gray border mb-0 dropdown-toggle shadow-none" id="dropdownTable" data-bs-toggle="dropdown" style={{ color: "#14212E" }} aria-expanded="false">
                                {selectedGraphFilter?.label ?? ''}
                            </a>
                            <ul className="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5" aria-labelledby="dropdownTable">
                                {filters.map((filter, i) =>
                                    <li key={i} ><a className="dropdown-item border-radius-md" key={i} onClick={() => getOrderCount(filter)}>{filter.label}</a></li>
                                )
                                }
                            </ul>
                        </div>
                    </>
                }>
                    {<LineGraph data={orderCount} height={"250rem"} label={t('common.orders')} />}
                </Card>
            </div>

            <div className="d-flex flex-row flex-lg-nowrap flex-md-wrap flex-sm-wrap flex-wrap gap-4 mt-5">
                <div className="flex-grow-1">
                    <Card title={t('dashboard.orders-per-volume.title')} subtitle={t('dashboard.orders-per-volume.description')} options={
                    <>
                        <div className="dropdown float-lg-end pe-4">
                            <a className="btn btn-outline-gray border mb-0 dropdown-toggle shadow-none" id="dropdownTable" data-bs-toggle="dropdown" style={{ color: "#14212E" }} aria-expanded="false">
                                {selectedVolumeFilter?.label ?? ''}
                            </a>
                            <ul className="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5" aria-labelledby="dropdownTable">
                                {filters.map((filter, i) =>
                                    <li key={i} ><a className="dropdown-item border-radius-md" key={i} onClick={() => getOrderVolume(filter)}>{filter.label}</a></li>
                                )
                                }
                            </ul>
                        </div>
                    </>
                } footer={
                        <div className="d-flex flex-row justify-content-between">
                            <div>{t('dashboard.total-orders')}</div>
                            <div className="h6">
                                <CurrencyText amount={orderVolume?.totalOrdersAmount} currencyDisplay='code' />
                            </div>
                        </div>
                    }>
                        <PercentageBar percentages={[
                            orderVolume?.completedVolume.percentage,
                            orderVolume?.pendingVolume.percentage,
                            orderVolume?.declinedVolume.percentage,
                            orderVolume?.canceledVolume.percentage,
                        ]} colors={[
                            ordersVolumesStyle?.completed,
                            ordersVolumesStyle?.pending,
                            ordersVolumesStyle?.declined,
                            ordersVolumesStyle?.cancelled,
                        ]} />

                        <div className="mt-3 d-flex flex-row justify-content-between">
                            <div>
                                <div className='d-flex flex-row'>
                                    <div className='mx-2 align-self-center' style={{
                                        ...ordersVolumesStyle.completed,
                                        ...ordersVolumesStyle.circle,
                                    }}></div>
                                    <div>{t('common.completed')}</div>
                                </div>
                            </div>
                            <div className="text-bold">
                                <CurrencyText amount={orderVolume?.completedVolume.amount} currencyDisplay='code' />
                            </div>
                        </div>
                        <div className="mt-3 d-flex flex-row justify-content-between">
                            <div>
                                <div className='d-flex flex-row'>
                                    <div className='mx-2 align-self-center' style={{
                                        ...ordersVolumesStyle.pending,
                                        ...ordersVolumesStyle.circle,
                                    }}></div>
                                    <div>{t('common.pending')}</div>
                                </div>
                            </div>
                            <div className="text-bold">
                                <CurrencyText amount={orderVolume?.pendingVolume.amount} currencyDisplay='code' />
                            </div>
                        </div>
                        <div className="mt-3 d-flex flex-row justify-content-between">
                            <div>
                                <div className='d-flex flex-row'>
                                    <div className='mx-2 align-self-center' style={{
                                        ...ordersVolumesStyle.declined,
                                        ...ordersVolumesStyle.circle,
                                    }}></div>
                                    <div>{t('common.declined')}</div>
                                </div>
                            </div>
                            <div className="text-bold">
                                <CurrencyText amount={orderVolume?.declinedVolume.amount} currencyDisplay='code' />
                            </div>
                        </div>
                        <div className="mt-3 d-flex flex-row justify-content-between">
                            <div>
                                <div className='d-flex flex-row'>
                                    <div className='mx-2 align-self-center' style={{
                                        ...ordersVolumesStyle.cancelled,
                                        ...ordersVolumesStyle.circle,
                                    }}></div>
                                    <div>{t('common.cancelled')}</div>
                                </div>
                            </div>
                            <div className="text-bold">
                                <CurrencyText amount={orderVolume?.canceledVolume.amount} currencyDisplay='code' />
                            </div>
                        </div>
                    </Card>
                </div>
                <div className=" flex-shrink-1">
                    <Card title={t('common.orders')} footer={
                        <div className="d-flex flex-row justify-content-between">
                            <div>{t('dashboard.total-orders')}</div>
                            <div className="h6">
                                <CurrencyText amount={orderVolume?.totalOrdersAmount} currencyDisplay='code' />
                            </div>
                        </div>
                    }>
                        <div className='text-center'>
                            <div className='mx-auto' style={{width: '10rem'}}>
                                <Pie
                                options={{
                                    plugins: {
                                        tooltip: {
                                            callbacks: {
                                                label: function (context) {
                                                    return context.label + ': ' + context.parsed + '%';
                                                }
                                        },
                                        },
                                    }
                                }}
                                data={
                                    {
                                        datasets: [{ data: [orderVolume?.activeOrdersPercentage, orderVolume?.rejectedOrdersPercentage], backgroundColor: ['#F5B335', '#fde8c2'] }] 
                                    }
                                        } />
                            </div>
                            <div className='row mt-4'>
                                <div className='col-6'>
                                    <div className='d-flex flex-row'>

                                        <Col>
                                            <div className="d-flex flex-row">
                                                <div className='me-2 align-self-center border-0' style={{
                                                    backgroundColor: '#F5B335',
                                                    ...ordersVolumesStyle.circle,
                                                }}></div>
                                                <div className='text-nowrap'>{t('dashboard.active-orders')}</div>
                                            </div>
                                            <div className='ms-auto text-center h6'>{orderVolume?.activeOrders}</div>
                                        </Col>

                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='d-flex flex-row'>

                                        <Col>
                                            <div className='d-flex flex-row'>
                                                <div className='me-2 align-self-center border-0' style={{
                                                    backgroundColor: '#fde8c2',
                                                    ...ordersVolumesStyle.circle,
                                                }}></div>
                                                <div className='text-nowrap'>{t('dashboard.rejected-orders')}</div>
                                            </div>

                                            <div className='ms-auto text-center h6'>{orderVolume?.rejectedOrders}</div>
                                        </Col>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>


            </div>

            <div className="mt-5">
                <Card title={t('dashboard.latest-transactions')}>
                    <DataTable
                        columns={columns}
                        data={orders?.MerchantOrderListItemRs}
                        customStyles={TableStyles}
                        onRowClicked={(row) => { navigate(`/orders/details/${row.orderId}`) }}
                    />
                </Card>

            </div>
        </>
    );
}

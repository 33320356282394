/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import DataTable from 'react-data-table-component';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { pdfFromReact } from "generate-pdf-from-react-html";
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { setLoadingSpinner, setSidebar } from '../../redux/reducers/settingsSlice';
import { store } from '../../redux/store';
import { BackIcon } from '../../utils/Icons';
import { orderDetailApi} from "./hook/orderDetailApi";
import {columns, OrderDetail} from "./hook/ScreenHelper";
import { StatusLabel } from '../../components/StatusLabel/StatusLabel';

interface IProps {
    sidebar?: boolean;
}

export const OrderDetails: React.FC<IProps> = () => {
    const [t, i18n] = useTranslation();
    const navigate = useNavigate();
    const {orderId} = useParams();
    const  isAR: boolean = i18n.language === "ar";
    const [orderDetail,setOrderDetail] = useState<OrderDetail>();
    const [prepareToPrint, setPrepareToPrint] = React.useState(false);

    useEffect(() => {
        store.dispatch(setSidebar('hide'))
        if(orderId != null){
        getOrderDetail();
        }
    }, []);

    const getOrderDetail =  () => {
          orderDetailApi(orderId, (success, data, errorMsg) => {
             if (!success) {
                 console.log(errorMsg);
                 onPressBack();
             }
             setOrderDetail(data);
         });
    }

    const onPressBack = () => {
        store.dispatch(setSidebar('show'))
        navigate('/orders')
    }

    const printPDF = () => {
        store.dispatch(setLoadingSpinner(true))
        setTimeout(() => setPrepareToPrint(true), 300);
        setTimeout(() => pdfFromReact("#element-to-print", "order-details-no-"+ orderId, "l", true, false), 600);
        setTimeout(() => setPrepareToPrint(false), 900);
        setTimeout(() => store.dispatch(setLoadingSpinner(false)), 1000);
    }

    return (orderDetail?.orderId &&
        <>
            <div className="row justify-content-center">
                <div className="col-md-10 " id="element-to-print">
                    <div className="card">
                        <div className="card-body p-3 mt-3">
                            <div className='d-flex flex-xs align-items-center justify-content-between flex-column flex-md-row'>
                                <div className='left-side d-flex align-items-start'>
                                    <button type="button" style={{ transform: isAR ? 'rotate(180deg)' : 'rotate(0deg)' }} className={"btn btn-outline-primary btn-sm mb-0 bg-lightyellow border border-0 px-3 "+ ( prepareToPrint ? "d-none" : null)}  onClick={onPressBack}><BackIcon /></button>
                                    <h4 className='px-4'>
                                        {t('order_details.order-details')}
                                        <p className='grey-64 font-weight-normal m-0'>
                                            {orderDetail?.creationDate}
                                        </p>
                                    </h4>
                                    <StatusLabel status={orderDetail?.status} subStatus={orderDetail?.orderSubStatus} />
                                </div>
                                <button type="button" className={"btn btn-black bg-grey-100 white px-5 py-3 " + ( prepareToPrint ? "d-none" : null)} onClick={printPDF}>{t('buttons.export_pdf')}</button>
                            </div>
                            <hr className='bg-grey-64 h-1' />
                            <div className="row">
                                <div className="col-lg-12 ms-auto text-center mt-5 mt-lg-0">
                                    <div className="border-0 bg-gray-100 rounded-2 p-5 blue_gradient">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className='item text-start mt-4 mt-md-0'>
                                                    <p className="text-sm mb-0 white">{t('order_details.loan_id')}:</p>
                                                    <p className="text-md mb-0 white fw-bold">{orderDetail?.loanId}</p>
                                                </div>
                                                <div className='item text-start mt-4 mt-md-4'>
                                                    <p className="text-sm mb-0 white">{t('order_details.order_reference_id')}:</p>
                                                    <p className="text-md mb-0 white fw-bold">
                                                        <OverlayTrigger
                                                            overlay={(props) => (
                                                                <Tooltip {...props} >
                                                                    <p className="text-sm mb-0 white">{t('order_details.order_reference_id')}:</p>
                                                                    <p className="text-sm mb-0 white"><strong>{orderDetail?.orderReferenceId}</strong></p>
                                                                </Tooltip>
                                                            )}
                                                            placement="auto"
                                                        ><p className='d-inline fw-bolder'>{orderDetail?.orderReferenceId}</p></OverlayTrigger>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className='item text-start mt-4 mt-md-0'>
                                                    <p className="text-sm mb-0 white">{t('order_details.customer_name')}</p>
                                                    <p className="text-md mb-0 white fw-bold">
                                                        {isAR ? orderDetail?.customerArabicFirstName + " " + orderDetail?.customerArabicFamilyName
                                                        : orderDetail?.customerEnglishFirstName + " " + orderDetail?.customerEnglishLastName
                                                        }
                                                    </p>
                                                </div>
                                                <div className='item text-start mt-4 mt-md-4'>
                                                    <p className="text-sm mb-0 white">{t('order_details.total_amount')}</p>
                                                    <p className="text-md mb-0 white fw-bold"> {isAR ?  orderDetail?.amount + " " + t('sar') : t('sar')+ " " + orderDetail?.amount   }</p>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className='item text-start mt-4 mt-md-0'>
                                                    <p className="text-sm mb-0 white">{t('order_details.items_no')}</p>
                                                    <p className="text-md mb-0 white fw-bold">{orderDetail?.itemCount} {t('order_details.items')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <DataTable
                                    columns={columns(t, isAR)}
                                    data={orderDetail?.items}
                                    highlightOnHover
                                    customStyles={{headCells: {
                                        style: {
                                            backgroundColor: '#FAFBFB',
                                            fontWeight: 'bold',
                                        },
                                        draggingStyle: {
                                            color: '#000'
                                        }
                                    }}}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    );
}

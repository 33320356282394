import React from 'react';
import './Loader.css';
export const Loader: React.FC = () => {

  return (
    <div className='loader-container'>
        <div className="loader">
          <div className="spinner-border text-warning"  role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <p className='pt-3'>loading...</p>
        </div>
    </div>
  );
}


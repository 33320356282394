export const hasUpperCase = (str :string): boolean => str !== str.toLowerCase();
export const hasLowerCase = (str :string): boolean => str !== str.toUpperCase();
export const hasNumbers = (str :string): boolean => /[0-9]/.test(str);
export const hasSpicialChar = (str :string): boolean => /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(str);
export const isEmail = (email: string): boolean => 
                            email.match(
                                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) 
                            ? true : false;
export const isPhone = (phone: string): boolean => {
    console.log( "startswith:" + phone.charAt(0), "phone length: " +phone.length)
    if (((phone.charAt(0) === '5'  && phone.length === 9)
        || (phone.charAt(0) === '0' && phone.length === 10))
        && !isNaN(+phone)) 
        return true;
    else 
        return false;
}
import { ChartData, ChartOptions, ChartType } from "chart.js";
import { useEffect, useState } from "react";
import { Chart } from "react-chartjs-2";
import 'chartjs-adapter-moment';
interface IProps {
    data: { x: string, y: number }[];
    options?: ChartOptions<ChartType>;
    label?: string;
    height?: string;
}

export const LineGraph: React.FC<IProps> = ({ data, options, label, height = "50rem" }) => {
    const [chartData, setChartData] = useState<ChartData>({ labels: [], datasets: [] });

    
    const defaultOptions: ChartOptions<ChartType> = {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false
                },
                type: 'time',
                time: {
                    unit: 'day',
                    tooltipFormat: 'DD/MM/YYYY',
                },
            }
        },
        elements: {
            line: {
                tension: 0.35
            },
        },
        plugins: {
            legend: {
                display: false
            },
            filler: {
                propagate: false
            },
            tooltip:{
                mode: 'index',
                intersect: false,
            }

        },
        hover: {
            mode: 'index',
            intersect: false
        },

    };

    useEffect(() => {

        setChartData({
            labels: data.map((d) => d.x),
            datasets: [
                {
                    label: label,
                    data: data.map((d) => d.y),
                    fill: "start",
                    backgroundColor: (context) => {
                        const ctx = context.chart.ctx;
                        const gradientStroke1 = ctx.createLinearGradient(0, 230, 0, 50);
                        gradientStroke1.addColorStop(1, 'rgba(0,149,207,0.33)');
                        gradientStroke1.addColorStop(0.3, 'rgba(0,149,207,0.1)');
                        gradientStroke1.addColorStop(0, 'rgba(0,149,207,0.04)');

                        return gradientStroke1;
                    },
                    borderColor: "rgba(0, 125, 173, 1)",
                    borderWidth: 2,
                    pointRadius: 0,
                    pointHoverRadius: 10,
                    pointHoverBackgroundColor: "rgba(0, 125, 173, 1)",
                    pointHoverBorderColor: "#fff",
                    pointHoverBorderWidth: 5,

                }
            ]
        });
    }, [data]);
    return (
        <Chart type='line' height={height} options={options ?? defaultOptions} data={chartData} />
    );
}
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOrderPagination } from "../../Orders/hooks/orderListApi";
import { fetchCustomerOrders, fetchCustomers } from "./customerListApi";
import { ICustomerOrdersFilters, ICustomersFilters, ICustomersPagination } from "./ScreenHelper";


export interface ICustomerAPIResponse {
    customers: ICustomersPagination,
    filters: ICustomersFilters,
    customerOrders?: IOrderPagination,
    customerOrdersFilters?: ICustomerOrdersFilters
}

const initialState: ICustomerAPIResponse = {
    customers: {
        currentPage: 1,
        pageCount: 0,
        pageSize: 10,
        totalCustomers: 0,
        customerList: [], 
    },
    filters: {page: 1, size: 30, searchString: ""},
    customerOrdersFilters: {page: 1, size: 30}
}

const CustomersSlice = createSlice({
    initialState,
    name: "customers",
    reducers: {
        changeFillter(state, action: PayloadAction<{filterName: string, value: any}[]>) {
            // eslint-disable-next-line array-callback-return
            action.payload.map((item) => {
                switch (item.filterName) {
                    case "page":
                        state.filters.page = item.value;
                        break;
                    case "size":
                        state.filters.size = item.value;
                        break;
                    case "search":
                        state.filters.searchString = item.value;
                        break;
                    case "sortBy":
                        state.filters.sortBy = item.value;
                        break;
                    case "sortByColumn":
                        state.filters.sortByColumn = item.value;
                        break;
                }
            });
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCustomers.fulfilled, (state, action) => {
            state.customers = action.payload.customers;
        });
        builder.addCase(fetchCustomerOrders.fulfilled, (state, action) => {
            state.customerOrders = action.payload;
        });
        
    }
});

export const { changeFillter } = CustomersSlice.actions;
export default CustomersSlice.reducer;
import React, { ReactNode } from 'react';


interface IProps {
  title: string;
  subtitle?: string;
  children?: ReactNode;
  footer?: JSX.Element;
  options?: JSX.Element;
}


export const Card: React.FC<IProps> =
  ({ title, subtitle, children, footer, options }) => {

    return (
      <>
        <div className="card col" style={{ height: '100%'}}>
          <div className="card-header pb-0">
            <div className="row">
              <div className="col-md-6 col-12">
                <h6>{title}</h6>
                {subtitle && <p className="text-sm mb-0">{subtitle}</p>}
              </div>
              <div className="col-md-6 col-12 my-auto text-end d-flex justify-content-end">
                {options && options}
              </div>
            </div>
          </div>
          <div className="row mt-4 px-5">
                  <hr className='border-bottom border-dark border-2' />
                </div>
          <div className="card-body px-4 pb-2">
            {children}
          </div>
          {footer &&
              <>
                <div className="px-5">
                  <hr className='border-bottom border-dark border-2' />
                </div>
                <div className='px-4 py-2 mb-2'>{footer}</div>
              </>
            }
        </div>
      </>
    );
  }


/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getInfoOTPAPI, resendOTPAPI, verifyOTPAPI } from './hook/API';
import background from "../../../assets/images/curved-6.jpg";
import { useTranslation } from 'react-i18next';
import { DEFAULT_OTPCODES, onKeyUpHandler, OTPCodes, OTPCodesRef, showBlockedAlert, showWrongOTPAlert, stateType } from './hook/ScreenHelper';
import { ErrorIcon, LogoSmallIcon, SuccessIcon } from '../../../utils/Icons';
import { store } from '../../../redux/store';
import { setErrorMsg } from '../../../redux/reducers/settingsSlice';

export const OTP: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const stateData = location.state as stateType;

    const [otp, setOtp] = useState<OTPCodes>(DEFAULT_OTPCODES);
    const [ref] = useState<OTPCodesRef>({otp_1: useRef(null), otp_2: useRef(null), otp_3: useRef(null), otp_4: useRef(null)});
    const [counter, setCounter] = React.useState(60);
    const [resendItem, setResendItem] = React.useState(false);
    const [otpValue, setOtpValue] = React.useState('');

    useEffect(() => {
        ref.otp_1.current.focus();

        // call api to get otp info
        getInfoOTPAPI(stateData, (success) => null);
    }, []);

    React.useEffect(() => {
        const timer =
          counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    React.useEffect(() => {
        // get the value of otp from refs
        setOtpValue(otp.otp_1 + otp.otp_2 + otp.otp_3 + otp.otp_4);
    }, [otp]);

    const submitButtonHandler = (stateData: stateType) => {
        // get the value of otp from refs
        const otpValue: string = ref.otp_1.current.value + ref.otp_2.current.value + ref.otp_3.current.value + ref.otp_4.current.value;
        setOtpValue(otpValue);
        console.log(otpValue);
        // call api to verify otp
        verifyOTPAPI(stateData.email, stateData.phone, otpValue, (success, errorMsg) => {
            if (success) {
                store.dispatch(setErrorMsg({
                    icon: <SuccessIcon />,
                    title: t('otp.success_msg_title'),
                    message: t('otp.success_msg_desc'),
                    acceptBtnTitle: t('buttons.login'),
                    acceptBtnAction: () => navigate('/login')
                }));
            } else {
                if (errorMsg === "blocked")
                    showBlockedAlert(<ErrorIcon />, t);
                else if (errorMsg === "false")
                    showWrongOTPAlert(<ErrorIcon />, t);
            }
        });
    };

    const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => 
        onKeyUpHandler(e, otp, setOtp, ref)

    const resend = () => {
        setCounter(60);
        resendOTPAPI(stateData, (success) => {
            if (success) 
                ref.otp_1.current.focus();
        });
        setResendItem(true);
    }

    return (
        <section>
            <div className="page-header min-vh-100">
                <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        
                        <div className="oblique position-absolute top-0 h-100 d-md-block d-none me-n8" style={{ width: '30%' }}>
                            <div className="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" style={{ backgroundImage: `url(${background})` }}>
                            </div>
                            <img style={{width: '90%', margin: 'auto', position: 'absolute', inset: 0}} src={require('../../../assets/icons/logo-with-words.png')} alt="" />
                        </div>
                    </div>
                    <div className="col-md-8 d-flex flex-column mx-auto">
                        <div className="card card-plain mt-md-6">
                            <div className="card-header  d-flex justify-content-center d-md-none">
                                <LogoSmallIcon width={"75%"} />
                            </div>
                            <div className="card-header pb-0 text-left bg-transparent">
                                <h3 className="fw-normal text-dark">{t("otp.title")}</h3>
                                <h6 className="fw-normal text-dark">{t("otp.description")}</h6>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6 d-flex flex-column ">
                                        <div className="mb-3 mt-5 d-flex" style={{ paddingInlineEnd: "40%" }}>
                                            <input ref={ref.otp_1} type="password" className="form-control me-2 otp" name="OTP1" value={otp.otp_1} onKeyUp={onKeyUp}/>
                                            <input ref={ref.otp_2} type="password" className="form-control me-2 otp" name="OTP2" value={otp.otp_2} onKeyUp={onKeyUp}/>
                                            <input ref={ref.otp_3} type="password" className="form-control me-2 otp" name="OTP3" value={otp.otp_3} onKeyUp={onKeyUp}/>
                                            <input ref={ref.otp_4} type="password" className="form-control me-2 otp" name="OTP4" value={otp.otp_4} onKeyUp={onKeyUp}/>
                                        </div>
                                    </div>
                                    <div className="col-md-12 d-flex flex-column fw-bolder">
                                        {resendItem && <p className='text-success fw-bolder m-0'><small>{t('otp.resend_success_msg')}</small></p>}
                                        <div className='d-flex flex-row'>
                                            {counter === 0 && (
                                                <>
                                                    <p><small>{t("otp.resend_msg")}</small></p>
                                                    <button type="button" className="btn btn-link p-0 px-1" onClick={resend}>{t("buttons.resend")}</button>
                                                </>
                                            )}
                                            {counter > 0 && <p><small className='fw-bolder'>{t("otp.resend_in")} <span className='text-warning'>00:{counter < 10 ? '0' + counter : counter}</span></small></p>}
                                        </div>
                                    </div>
                                    <div className="text-center d-flex flex-column flex-md-row mt-4 justify-content-between">
                                        <a href="login" className="btn btn-secondary px-7 py-3">{t('buttons.go_back')}</a>
                                        <button type="button" className="btn btn-warning px-7 py-3" disabled={otpValue.length !== 4} onClick={() => submitButtonHandler(stateData)}>{t('buttons.continue')}</button>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
                </div>
            </div>
        </section>
    );
}


import { isPhone } from "../../../utils/Validation";

export interface userLoginData {
    businessName: string;
    contactName: string;
    businessCr: string;
    contactNumber: string;
    contactEmail: string;
}
export const DEFAULT_DATA: userLoginData= {businessName: '', contactName: '', businessCr: '', contactNumber: '', contactEmail: ''}

export const onChangePhone = (
    e: React.ChangeEvent<HTMLInputElement>, 
    setPhone: React.Dispatch<React.SetStateAction<string>>,
    setErrors: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
    const val = e.target.value;
    if (!isPhone(val)) {
        setErrors(false)
        return;
    }
    else {
        setPhone(e.target.value)
        setErrors(true)
    }
}


import API from '../../../hooks/api';

// create a new interface for the response data
export interface IAggregatesData {
    customerRate: string, 
    completedOrdersRate: string, 
    completedOrders: string, 
    customers: string, 
    pendingOrders: string, 
    pendingOrdersRate: string, 
}

export const aggregatesAPI = async (startDate: string, endDate: string,
        callBack: (success: boolean, data: IAggregatesData,  errorMsg: string | null) => void = () => {}
    ) => {
        const url = `order/merchant/dashboard/aggregate`;
    await API.get(url, {params: {startDate, endDate}})
    .then((res) => {
        callBack(true, res.data, null);
    }).catch((err) => {
        callBack(false, null, err);
    });
}

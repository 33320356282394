import { isEmail } from "../../../../utils/Validation";

export interface userLoginData {
    email: string;
    phone: string;
}

export const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>, setEmail: React.Dispatch<React.SetStateAction<string>>) => {
    // validate the email address
    isEmail(e.target.value) ? setEmail(e.target.value) : setEmail('')
}
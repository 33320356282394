import { ChangeResult } from "multi-range-slider-react";
import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DateObject } from "react-multi-date-picker";
import { CustomDatePicker } from "../../components/CustomDatePicker/CustomDatePicker";
import { CustomMultiRangeSlider } from "../../components/MultiRangeSlider/CustomMultiRangeSlider";
import { useCurrencyText } from "../../utils/hooks";
import { IOrdersAggregates } from "./hooks/ordersAggregatesApi";

interface IProps {
    filterDispatcher: (action: { type: 'date' | 'amount' | 'status', payload: any }) => void;
    setShowFilters: (boolean) => void;
    selectedFilters: { dates: DateObject[], amount: number[], states: string[] }
    ordersAggregates: IOrdersAggregates;
}

export const OrdersFiltersPanel: React.FC<IProps> = ({ setShowFilters, selectedFilters, filterDispatcher , ordersAggregates}) => {
    const [t] = useTranslation();
    const [minRangeValue, setMinRangeValue] = useState(ordersAggregates.minOrderAmount);
    const [maxRangeValue, setMaxRangeValue] = useState(ordersAggregates.maxOrderAmount);
    const [states, setStates] = useState([
        { name: 'CANCELLED', label: "Canceled", isChecked: false, color: 'dark-negative' },
        { name: 'PENDING', label: "PENDING", isChecked: false, color: 'dark-critical' },
        { name: 'UNDER_CANCEL', label: "UNDER_CANCEL", isChecked: false, color: 'dark-critical' },
        { name: 'DECLINED', label: "DECLINED", isChecked: false, color: 'dark-negative' }
    ]);

    const handleRangeChange = (e: ChangeResult) => {
        
        setMinRangeValue(e.minValue);
        setMaxRangeValue(e.maxValue);
    }

    useEffect(() => {
        filterDispatcher({ type: 'amount', payload: [minRangeValue, maxRangeValue] });
    // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [minRangeValue, maxRangeValue]);

    useEffect(() => {
        setStates(states.map(state => {
            return {
                ...state,
                isChecked: selectedFilters.states.includes(state.name)
            }
        }))

    }, [selectedFilters]);

    return (
        <>
            <div className="card shadow-lg">
                <div className="card-header pb-0 pt-3">
                    <div className="float-start">
                        <h5 className="mt-3 mb-0">{t('common.filters')}</h5>
                    </div>
                    <div className="float-end mt-4">
                        <button
                            onClick={() => { setShowFilters(false) }}
                            type="button"
                            className='btn btn-link btn-sm text-dark p-0'
                        >X</button>
                    </div>
                </div>
                <div className="card-body pt-2">
                    <div className="border-top border-grey mt-2 mb-3"></div>
                    <Col>
                        <div className='text-black fs-6'>
                            {t('common.date-duration')}
                        </div>
                        <CustomDatePicker placeholder={t('common.select-date-duration')} dateValue={selectedFilters.dates} onChange={function (dateValue: DateObject): void {
                            filterDispatcher({ type: 'date', payload: dateValue });
                        }} range={true}></CustomDatePicker>

                        <div className='mt-5 text-black'>{t('common.amount')}</div>
                        <div dir="ltr">
                            <CustomMultiRangeSlider
                                min={ordersAggregates.minOrderAmount}
                                max={ordersAggregates.maxOrderAmount}
                                minValue={ordersAggregates.minOrderAmount}
                                maxValue={ordersAggregates.maxOrderAmount}
                                onChange={handleRangeChange}
                                ruler={false}
                                label={false}
                                minCaption={useCurrencyText(String(minRangeValue), 'symbol')}
                                maxCaption={useCurrencyText(String(maxRangeValue), 'symbol')}
                            />
                        </div>


                        <div className='mt-5 text-black'>{t('common.status')}</div>
                        <div className='d-flex flex-column'>
                            {states.map((state, index) => {
                                return (
                                    <div className='d-flex flex-row align-items-center justify-content-between'>
                                        <div className="d-flex flex-row align-items-center" key={index}>
                                            <div>
                                                <input type="checkbox" checked={state.isChecked} onChange={(e) => {
                                                    const newStates = [...states];
                                                    newStates[index].isChecked = e.target.checked;
                                                    setStates(newStates);
                                                    filterDispatcher({ type: 'status', payload: newStates.filter(state => state.isChecked).map(state => state.name) });
                                                }} />
                                            </div>
                                            <div>
                                                <label className={`font-weight-normal px-2 ${state.color}`}>{state.label}</label>
                                            </div>
                                        </div>
                                        <div className='py-1 rounded bg-gray-100 text-xs'>
                                            {ordersAggregates.orderStatusCount[state.name]}
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </Col>
                </div>
            </div>
        </>
    );
};
interface IProps {
    status: string;
    subStatus?: string;
    className?: string;
}


const statusClasses = {
    completed: 'dark-positive bg-light-positive',
    captured: 'dark-positive bg-light-positive',
    approved: 'dark-positive bg-light-positive',
    pending: 'dark-critical bg-light-critical',
    under_cancel: 'dark-critical bg-light-critical',
    cancelled: 'dark-negative bg-light-negative',
    declined: 'dark-negative bg-light-negative',
};

export const StatusLabel: React.FC<IProps> = ({
    status,
    subStatus,
    className,
    ...props
}) => {

    const statusClass = statusClasses[status.toLocaleLowerCase()] || statusClasses['pending'];

    return (
        <label className={ `${className} ${statusClass}  font-weight-normal p-2 text-center`} style={{ maxWidth: "10rem", borderRadius: '1rem' }}>
            {status}
            <p className='m-0' style={{ fontSize: "0.7rem" }}>
                {subStatus}
            </p>
        </label>
    );
};
import API from '../../../hooks/api';

export enum OrderStatus {
    CANCELLED = 'Cancelled',
    COMPLETED = 'Completed',
    PENDING = 'Pending',
    DECLINED = 'Declined',
}

interface IOrderVolume{
    amount: string,
    percentage: number
}
export interface IOrderVolumeData {
    activeOrders: number,
    rejectedOrders: number,
    totalOrders: number,
    activeOrdersPercentage: number,
    rejectedOrdersPercentage: number,
    totalOrdersAmount: string,
    completedVolume: IOrderVolume,
    pendingVolume: IOrderVolume,
    declinedVolume: IOrderVolume,
    canceledVolume: IOrderVolume,

}

export const orderVolumeAPI = async (startDate: string, endDate: string,
        callBack: (success: boolean, data: any,  errorMsg: string | null) => void = () => {}
    ) => {
        const url = `order/merchant/dashboard/volumePerStatus`;
    await API.get(url, {params: {startDate, endDate}})
    .then((res) => {
        const dataArray: IOrderVolumeData = {
            activeOrders: res.data.activeOrders,
            rejectedOrders: res.data.rejectedOrders,
            totalOrders: res.data.totalOrders,
            activeOrdersPercentage: res.data.activeOrdersPercentage,
            rejectedOrdersPercentage: res.data.rejectedOrdersPercentage,
            totalOrdersAmount: res.data.totalOrdersAmount,
            completedVolume: {
                ...res.data.orderVolume.find((v) => v.status === OrderStatus.COMPLETED) as {amount: string, percentage: number}
            },
            pendingVolume: {
                ...res.data.orderVolume.find((v) => v.status === OrderStatus.PENDING) as {amount: string, percentage: number}
            },
            declinedVolume: {
                ...res.data.orderVolume.find((v) => v.status === OrderStatus.DECLINED) as {amount: string, percentage: number}
            },
            canceledVolume: {
                ...res.data.orderVolume.find((v) => v.status === OrderStatus.CANCELLED) as {amount: string, percentage: number}
            },
        };

        callBack(true, dataArray, null);
    }).catch((err) => {
        callBack(false, null, err);
    });
}

import { localStorageHelper } from './localStorageHelper';
import { store } from './../redux/store';
import axios from "axios";
import { setErrorMsg, setLoadingSpinner } from '../redux/reducers/settingsSlice';

const { REACT_APP_BASE_URL } = process.env;

const API = axios.create({
    baseURL: `${REACT_APP_BASE_URL}/api/v1`,
});

API.interceptors.request.use(async (req) => {    
    store.dispatch(setLoadingSpinner(true))
    await new Promise(resolve => setTimeout(resolve, 500))

    if (typeof localStorageHelper.load("profile") !== 'undefined' ) {
        if (typeof localStorageHelper.load("profile").access_token !== 'undefined' ) {
            req.headers = {
                'Authorization': `Bearer ${
                    localStorageHelper.load("profile").access_token
                }`
            };
        }
    }
    return req;
  });
  
API.interceptors.response.use((response) => {
    store.dispatch(setLoadingSpinner(false))

    
    return response;
}, (error) => {
    store.dispatch(setLoadingSpinner(false))
    // if (error.response.status === 401) {
    //     localStorageHelper.remove('profile');
    //     window.location.reload();
    // } 
    // else if (error.response.status >= 406) {
    //     store.dispatch(setErrorMsg({
    //         title: "Something went wrong",
    //         message: "Please exit or contact us on support@QuaraFinance.com"
    //     }))
    // }

    if (error.response && error.response.data) {
        return Promise.reject(error.response.data);
    }

    return Promise.reject(error.message);
});



export default API
import { configureStore } from "@reduxjs/toolkit";
import CustomersSlice from "../pages/Customers/hooks/CustomersSlice";
import settingsSlice from "./reducers/settingsSlice";
import UserProfileSlice from "./reducers/UserProfileSlice";
// import AuthReducer from './features/authSlice';

export const store = configureStore({
  reducer: {
    settings: settingsSlice,
    user_profile: UserProfileSlice,
    customers: CustomersSlice
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
